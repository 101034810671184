import { IDateTimeRange, IDate, ITimeRange } from "./types";

/* Captures a time range, e.g. "8:00am-12:00pm" or "7-9" or "9-10am" */
const TIME_PATTERN = "((?:[0-9]+(?::[0-9]+)?)\\s*(?:am|pm)?)";
/* Group 1 = hours, group 2 = minutes, group 3 = am/pm */
const TIME_PATTERN_CAPTURING = "(?:([0-9]+)(:[0-9]+)?)\\s*(am|pm)?";
const TIME_RANGE_JOINER_PATTERN = "(?:\\s*(?:-|to|thru|through)\\s*)";
const TIME_RANGE_PATTERN = `${TIME_PATTERN}${TIME_RANGE_JOINER_PATTERN}${TIME_PATTERN}`;
export const timeRangeRegex = new RegExp(TIME_RANGE_PATTERN, "i");
const timeRegex = new RegExp(TIME_PATTERN_CAPTURING);

/* Captures a day of the week */
const SUNDAY_PATTERN = "SU|SUN|SUNDAY";
const MONDAY_PATTERN = "M|MO|MON|MONDAY";
const TUESDAY_PATTERN = "T|TU|TUE|TUES|TUESDAY";
const WEDNESDAY_PATTERN = "W|WE|WED|WEDNESDAY";
const THURSDAY_PATTERN = "TH|THU|THUR|THURS|THURSDAY";
const FRIDAY_PATTERN = "F|FR|FRI|FRIDAY";
const SATURDAY_PATTERN = "SA|SAT|SATURDAY";
const DAY_OF_WEEK_PATTERN = `(?:(?:${SUNDAY_PATTERN})|(?:${MONDAY_PATTERN})|(?:${TUESDAY_PATTERN})|(?:${WEDNESDAY_PATTERN})|(?:${THURSDAY_PATTERN})|(?:${FRIDAY_PATTERN})|(?:${SATURDAY_PATTERN}))\\.?`;
const startDayOfWeekRegex = new RegExp(`^(${DAY_OF_WEEK_PATTERN})\\s`, "i");
export const dayOfWeekRegex = new RegExp(`^${DAY_OF_WEEK_PATTERN}$`, "i");

const NUMERIC_DATE_PATTERN = "(?:[0-9]{1,2}[\\/-][0-9]{1,2}[\\/-][0-9]{2,4})";
const MONTH_PATTERN =
  "(?:JAN|JANUARY|FEB|FEBRUARY|MAR|MARCH|APR|APRIL|MAY|JUN|JUNE|JUL|JULY|AUG|AUGUST|SEP|SEPT|SEPTEMBER|OCT|OCTOBER|NOV|NOVEMBER|DEC|DECEMBER)\\.?";
const WORDS_DATE_PATTERN = `(?:${MONTH_PATTERN}\\s*[0-9]{1,2}(?:st|nd|rd|th)?)(?:\\s*,?\\s*[0-9]{4})?`;
const WORDS_DATE_CAPTURE_PATTERN = `(?:(${MONTH_PATTERN})\\s*([0-9]{1,2})(?:st|nd|rd|th)?)(?:\\s*,?\\s*([0-9]{4}))?`;
const DATE_PATTERN = `(?:${NUMERIC_DATE_PATTERN}|${WORDS_DATE_PATTERN})`;
const numericDateRegex = /([0-9]{1,2})[\\/-]([0-9]{1,2})[\\/-]([0-9]{2,4})/i;
const wordDateRegex = new RegExp(WORDS_DATE_CAPTURE_PATTERN, "i");
const startDateRegex = new RegExp(`^(${DATE_PATTERN})`, "i");
export const dateRegex = new RegExp(`^${DATE_PATTERN}$`, "i");

const DAY_OF_WEEK_OR_DATE_PATTERN = `(?:${DAY_OF_WEEK_PATTERN}|${DATE_PATTERN})`;
const DATE_TIME_RANGE_JOINER = "\\s*(?:from|,|;)?\\s*";
const DATE_TIME_RANGE_PATTERN = `^(${DAY_OF_WEEK_OR_DATE_PATTERN})?${DATE_TIME_RANGE_JOINER}(${TIME_RANGE_PATTERN})${DATE_TIME_RANGE_JOINER}(${DAY_OF_WEEK_OR_DATE_PATTERN})?$`;
export const dateTimeRangeRegex = new RegExp(DATE_TIME_RANGE_PATTERN, "i");

// Error parsing

export const MONTH_NAMES = [
  "__DO_NOT_USE__",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MONTH_PREFIXES = [
  "__DO_NOT_USE__",
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

function getYear(yearStr: string | null | undefined) {
  if (yearStr) {
    try {
      const year = parseInt(yearStr, 10);
      if (year < 100) {
        return 2000 + year;
      }
      return year;
    } catch {
      /* Swallow number parsing errors */
    }
  }
  return undefined;
}

function getMonthNumber(monthStr: string | null | undefined) {
  if (monthStr) {
    const month = monthStr.toLowerCase();
    let monthNum = 0;
    MONTH_PREFIXES.forEach((prefix, idx) => {
      if (month.includes(prefix)) {
        monthNum = idx;
      }
    });
    if (monthNum === 0) {
      try {
        monthNum = parseInt(month, 10);
      } catch {
        /* Swallow error */
      }
    }
    return monthNum > 0 ? monthNum : undefined;
  }
  return undefined;
}

function safeGetDay(dayStr: string | null | undefined) {
  if (dayStr) {
    try {
      return parseInt(dayStr, 10);
    } catch {
      /* Swallow error */
    }
  }
  return undefined;
}

function getDate(dateStr: string | null | undefined): IDate | undefined {
  if (dateStr) {
    const numericDateMatches = numericDateRegex.exec(dateStr);
    if (numericDateMatches) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_match, monthStr, day, year] = numericDateMatches;
      return {
        month: getMonthNumber(monthStr),
        day: safeGetDay(day),
        year: getYear(year) || new Date().getFullYear(),
      };
    }

    const wordDateMatches = wordDateRegex.exec(dateStr);
    if (wordDateMatches) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_match, month, day, year] = wordDateMatches;
      return {
        month: getMonthNumber(month),
        day: safeGetDay(day),
        year: getYear(year) || new Date().getFullYear(),
      };
    }
  }
  return undefined;
}

function getDayOfWeek(dow: string | null | undefined): string | undefined {
  if (dow) {
    const dowLower = dow.toLowerCase();
    if (dowLower.startsWith("su")) {
      return "Sunday";
    } else if (dowLower.startsWith("m")) {
      return "Monday";
    } else if (dowLower.startsWith("tu") || dowLower === "t") {
      return "Tuesday";
    } else if (dowLower.startsWith("w")) {
      return "Wednesday";
    } else if (dowLower.startsWith("th")) {
      return "Thursday";
    } else if (dowLower.startsWith("f")) {
      return "Friday";
    } else if (dowLower.startsWith("sa")) {
      return "Saturday";
    }
  }
  return undefined;
}

/*
 * Determines whether a given time is most likely AM or PM
 * Logic:
 *   If the time is greater than or equal to 13:00, it is military time and should not include a PM conversion.
 *   If AM or PM is listed explicitly, use that value.
 *   If this value represents the end of a time interval and AM would make the end earlier than the start, use PM.
 *   Otherwise, return AM for values greater than 6:00 and PM for values less than 6:00 ¯\_(ツ)_/¯
 *
 * @param amPmStr: An explicit substring of the time, 'am' or 'pm' (case insensitive), if it exists
 * @param minuteOfDay: The minute of the day that this time represents
 * @param rangeStartMinuteOfDay: The minute of the day of the range start, if this is the end of the range.
 */
function isPm(
  amPmStr: string | null | undefined,
  minuteOfDay: number,
  rangeStartMinuteOfDay?: number
) {
  if (minuteOfDay >= 13 * 60) {
    return false;
  } else if (amPmStr) {
    return amPmStr.toLowerCase() === "pm";
  } else if (
    rangeStartMinuteOfDay !== undefined &&
    rangeStartMinuteOfDay > minuteOfDay
  ) {
    return true;
  } else {
    return minuteOfDay < 6 * 60;
  }
}

/*
 * Converts, e.g., "2:15pm" into 14 * 60 + 15 = 855
 * @param time: A time represented as a string
 * @param rangeStartMinuteOfDay: Used if this is the end of a range to ensure consistency when inferring am/pm
 *
 * @returns: the minute of the day corresponding to this time
 */
function getMinuteOfDay(
  time: string,
  rangeStartMinuteOfDay?: number
): number | undefined {
  try {
    const timeParts = timeRegex.exec(time);
    if (timeParts) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [_match, hoursStr, minutesStr, amPm] = timeParts;
      const hours = hoursStr ? parseInt(hoursStr, 10) : 0;
      const minutesParsed =
        minutesStr && minutesStr[0] === ":" ? minutesStr.substr(1) : undefined;
      const minutes = minutesParsed ? parseInt(minutesParsed, 10) : 0;
      const totalMinutes = hours * 60 + minutes;
      if (hours === 0 || hours === 12) {
        if (amPm === "am") {
          // Special case: 12:00am as end of range; consider it to be time 1440 rather than 0.
          // This is the only case where a shift can cross a day boundary.
          if (rangeStartMinuteOfDay !== undefined && minutes === 0) {
            return 24 * 60;
          } else {
            return minutes;
          }
        } else if (amPm === "pm") {
          return 12 * 60 + minutes;
        } else if (
          rangeStartMinuteOfDay !== undefined &&
          rangeStartMinuteOfDay > 12 * 60 + minutes
        ) {
          return minutes === 0 ? 24 * 60 : undefined;
        } else {
          return 12 * 60 + minutes;
        }
      }
      const pmAdjustment = isPm(amPm, totalMinutes, rangeStartMinuteOfDay)
        ? 12 * 60
        : 0;
      return totalMinutes + pmAdjustment;
    }
  } catch {
    /* Swallow number parsing exceptions */
  }
  return undefined;
}

export function parseDateTime(
  value: string,
  opts: {
    column?: number;
    priority?: number | undefined;
  } = {}
): IDateTimeRange | undefined {
  const matches = dateTimeRangeRegex.exec(value);
  if (matches) {
    let dayOfWeek = undefined;
    let date = undefined;
    const dateOrDay = matches[1] ? matches[1] : matches[5];
    if (dateOrDay) {
      const dateMatch = dateRegex.exec(dateOrDay);
      if (dateMatch) {
        date = dateMatch[0];
      } else {
        const dayOfWeekMatch = dayOfWeekRegex.exec(dateOrDay);
        dayOfWeek = dayOfWeekMatch && dayOfWeekMatch[0];
      }
    }
    const rangeStart = matches[3];
    const rangeEnd = matches[4];
    const startMinuteOfDay = getMinuteOfDay(rangeStart);
    const endMinuteOfDay = getMinuteOfDay(rangeEnd, startMinuteOfDay);
    const range =
      startMinuteOfDay && endMinuteOfDay
        ? { startMinuteOfDay, endMinuteOfDay }
        : undefined;
    let error = undefined;
    if (range && range.endMinuteOfDay < range.startMinuteOfDay) {
      error = new Error("End time cannot be earlier than start time.");
    }

    return {
      error,
      range,
      dayOfWeek: getDayOfWeek(dayOfWeek),
      date: getDate(date),
      column: opts.column,
      priority: opts.priority,
    };
  }

  return undefined;
}

function getSuffix(x: number) {
  if (x > 10 && x < 20) {
    return "th";
  } else if (x % 10 === 1) {
    return "st";
  } else if (x % 10 === 2) {
    return "nd";
  } else if (x % 10 === 3) {
    return "rd";
  }
  return "th";
}

export function getFormattedDayAndTime(dateTime: IDateTimeRange) {
  const day = dateTime.dayOfWeek
    ? dateTime.dayOfWeek
    : formatDate(dateTime.date);
  const time = dateTime.range ? formatTimeRange(dateTime.range, "-") : "";
  return {
    day,
    time,
  };
}

export function formatDate(date: IDate | undefined) {
  if (!date || !date.month || !date.day) {
    return "";
  }
  const month = MONTH_NAMES[date.month];
  const yearStr = date.year ? `, ${date.year}` : "";
  return `${month} ${date.day}${getSuffix(date.day)}${yearStr}`;
}

export function formatTime(minuteOfDay: number) {
  if (minuteOfDay === undefined) {
    return "Unknown";
  }

  const hours = Math.floor(minuteOfDay / 60);
  const amPmStr = hours < 12 || hours === 24 ? "am" : "pm";
  let adjustedHours = hours;
  if (hours < 1) {
    adjustedHours = 12;
  } else if (hours > 12) {
    adjustedHours = hours - 12;
  }
  const minutes = minuteOfDay % 60;
  const paddedMinutes = `${minutes < 10 ? "0" : ""}${minutes}`;

  return `${adjustedHours}:${paddedMinutes}${amPmStr}`;
}

export function formatDateAndTimeRange(dateAndTimeRange: IDateTimeRange) {
  if (
    !dateAndTimeRange.range ||
    dateAndTimeRange.range.startMinuteOfDay === undefined ||
    !dateAndTimeRange.range.endMinuteOfDay === undefined
  ) {
    return "";
  }

  let value = "";
  if (dateAndTimeRange.date) {
    value += `${formatDate(dateAndTimeRange.date)} from `;
  } else if (dateAndTimeRange.dayOfWeek) {
    value += `${dateAndTimeRange.dayOfWeek} from `;
  }
  value += formatTimeRange(dateAndTimeRange.range, "to");
  return value;
}

export function formatTimeRange(range: ITimeRange, separator: string) {
  return `${formatTime(range.startMinuteOfDay)} ${separator} ${formatTime(
    range.endMinuteOfDay
  )}`;
}

export function getParsingError(value: string): string {
  const timeRangeMatch = timeRangeRegex.exec(value);
  const dayOfWeekMatch = startDayOfWeekRegex.exec(value);
  const dateMatch = startDateRegex.exec(value);
  if (timeRangeMatch) {
    const startMinuteOfDay = getMinuteOfDay(timeRangeMatch[1]);
    const endMinuteOfDay = getMinuteOfDay(timeRangeMatch[2], startMinuteOfDay);
    const rangeStr =
      startMinuteOfDay && endMinuteOfDay
        ? formatTimeRange({ startMinuteOfDay, endMinuteOfDay }, "to")
        : timeRangeMatch[0];
    if (timeRangeMatch.index > 0) {
      return `Found time range "${rangeStr}", but could not parse "${value
        .substring(0, timeRangeMatch.index)
        .trim()}" as a day of the week or date.`;
    } else if (timeRangeMatch[0].length < value.length) {
      return `Found time range "${rangeStr}", but could not parse "${value
        .substring(timeRangeMatch[0].length)
        .trim()}" as a day of the week or date.`;
    }
  } else if (dayOfWeekMatch) {
    return (
      `Found day of week "${getDayOfWeek(
        dayOfWeekMatch[1]
      )}", but could not parse time range from ` +
      `"${value.substring(dayOfWeekMatch[1].length).trim()}"`
    );
  } else if (dateMatch) {
    return (
      `Found date "${formatDate(
        getDate(dateMatch[1])
      )}", but could not parse time range from ` +
      `"${value.substring(dateMatch[1].length).trim()}"`
    );
  }
  return "Failed to parse date and/or time.";
}
