import { useState, useMemo } from "react";
import { SchedulerState } from "../scheduler/store/schedulerStateContainer";
import { ColumnConfigurationScreen, IButtonConfig } from "./types";
import {
  IColumnMapping,
  AsyncData,
  ITableData,
} from "../scheduler/store/types";
import {
  FollowUpAnswerMap,
  IFollowUpQuestion,
  IParsedData,
  IValidationInfo,
  ISummary,
} from "../categories/types";
import { Category } from "../categories/category";
import { CategoryIdType } from "../categories/constants";
import { getCategory } from "../categories/categoryMap";

export interface IColumnConfigController {
  selectedColumns: Array<number>;
  selectedCategory: Category<any> | undefined;
  screen: ColumnConfigurationScreen;
  parsedData: IParsedData<any> | undefined;
  validationInfo: IValidationInfo | undefined;
  summary: ISummary | undefined;
  followUpQuestions: Array<IFollowUpQuestion>;
  followUpAnswers: FollowUpAnswerMap;
  currentFollowUpIndex: number;
  primaryButton: IButtonConfig;
  secondaryButton: IButtonConfig;
  toggleColumnSelection(column: number): void;
  selectCategory(categoryId: CategoryIdType): void;
  handleFollowUpAnswered(value: string): void;
  deleteMapping(category: string): void;
}

export function useColumnConfigController(
  requiredCategoryIds: Array<CategoryIdType>,
  tableData: AsyncData<ITableData>,
  columnMappings: Array<IColumnMapping>,
  setColumnMappings: (mappings: Array<IColumnMapping>) => void
): IColumnConfigController {
  const { page, setPage } = SchedulerState.useContainer();
  const [screen, setScreen] = useState(
    ColumnConfigurationScreen.SELECT_CATEGORY_OR_COLUMN
  );
  const [selectedColumns, setSelectedColumns] = useState<Array<number>>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    CategoryIdType | undefined
  >(undefined);
  const [followUpAnswers, setFollowUpAnswers] = useState<FollowUpAnswerMap>({});
  const [currentFollowUpIndex, setCurrentFollowUpIndex] = useState<number>(0);

  const selectedCategory = selectedCategoryId
    ? getCategory(selectedCategoryId)
    : undefined;

  const {
    parsedData,
    validationInfo,
    followUpQuestions,
    summary,
  } = useMemo(() => {
    if (
      !selectedCategory ||
      selectedColumns.length === 0 ||
      tableData.loadState !== "LOADED"
    ) {
      return {
        parsedValues: undefined,
        validationInfo: undefined,
        followUpQuestions: [],
        summary: undefined,
      };
    }
    const parsedData = selectedCategory.parseData(
      tableData.value,
      selectedColumns,
      followUpAnswers
    );
    const followUpQuestions = selectedCategory.getFollowUpQuestions(
      parsedData,
      selectedColumns
    );
    const validationInfo = selectedCategory.validate(
      tableData.value,
      parsedData,
      selectedColumns,
      followUpQuestions,
      followUpAnswers
    );
    const summary = selectedCategory.getSummary(
      parsedData,
      selectedColumns,
      validationInfo,
      followUpAnswers
    );
    return { parsedData, validationInfo, followUpQuestions, summary };
  }, [tableData, selectedColumns, selectedCategory, followUpAnswers]);

  const currentFollowUpQuestion =
    currentFollowUpIndex < followUpQuestions.length
      ? followUpQuestions[currentFollowUpIndex]
      : undefined;

  function toggleColumnSelection(column: number) {
    // Check if this column is already part of a mapping
    const existingMapping = columnMappings.find(m =>
      m.columns.some(c => c === column)
    );
    if (existingMapping) {
      if (selectedCategory) {
        return;
      } else {
        selectCategory(existingMapping.category);
        return;
      }
    }
    if (selectedColumns.some(c => c === column)) {
      setSelectedColumns(selectedColumns.filter(c => c !== column));
      if (selectedColumns.length === 1) {
        if (selectedCategoryId) {
          setScreen(ColumnConfigurationScreen.SELECT_COLUMN);
        } else {
          setScreen(ColumnConfigurationScreen.SELECT_CATEGORY_OR_COLUMN);
        }
      }
    } else {
      const updatedColumns = [...selectedColumns, column];
      updatedColumns.sort();
      setSelectedColumns(updatedColumns);
      if (selectedCategoryId) {
        setScreen(ColumnConfigurationScreen.CONFIRM_SELECTION);
      } else {
        setScreen(ColumnConfigurationScreen.SELECT_CATEGORY);
      }
    }
    if (followUpQuestions.length > 0) {
      setFollowUpAnswers({});
    }
  }

  function selectCategory(categoryId: CategoryIdType) {
    setSelectedCategoryId(categoryId);
    const categoryMapping = columnMappings.find(m => m.category === categoryId);
    const existingColumns: number[] = categoryMapping
      ? categoryMapping.columns
      : [];
    if (selectedColumns.length > 0 || existingColumns.length > 0) {
      const combinedColumns = [...existingColumns, ...selectedColumns];
      combinedColumns.sort();
      setScreen(ColumnConfigurationScreen.CONFIRM_SELECTION);
      setSelectedColumns(combinedColumns);
    } else {
      setScreen(ColumnConfigurationScreen.SELECT_COLUMN);
      setSelectedColumns(existingColumns);
    }

    if (categoryMapping) {
      setFollowUpAnswers(categoryMapping.followUpAnswers);
      setCurrentFollowUpIndex(0);
    } else if (followUpQuestions.length > 0) {
      setFollowUpAnswers({});
    }
  }

  function handleGoBackClick() {
    setPage(page - 1);
    window.scrollTo(0, 0);
  }

  function handleNextClick() {
    setPage(page + 1);
    window.scrollTo(0, 0);
  }

  function handleCancel() {
    setScreen(ColumnConfigurationScreen.SELECT_CATEGORY_OR_COLUMN);
    setSelectedCategoryId(undefined);
    setSelectedColumns([]);
  }

  function goToPreviousFollowUp() {
    if (currentFollowUpIndex === 0) {
      setScreen(ColumnConfigurationScreen.CONFIRM_SELECTION);
    } else {
      setCurrentFollowUpIndex(currentFollowUpIndex - 1);
    }
  }

  function goToNextFollowUp() {
    if (screen !== ColumnConfigurationScreen.ADDITIONAL_INFO) {
      setScreen(ColumnConfigurationScreen.ADDITIONAL_INFO);
      setCurrentFollowUpIndex(0);
    } else if (currentFollowUpIndex < followUpQuestions.length) {
      setCurrentFollowUpIndex(currentFollowUpIndex + 1);
    }
  }

  function handleFollowUpAnswered(value: string) {
    if (currentFollowUpQuestion) {
      setFollowUpAnswers({
        ...followUpAnswers,
        [currentFollowUpQuestion.id]: value,
      });
    }
  }

  function handleConfirm() {
    if (selectedCategoryId && selectedColumns.length > 0) {
      if (columnMappings.some(m => m.category === selectedCategoryId)) {
        setColumnMappings(
          columnMappings.map(
            (mapping: IColumnMapping): IColumnMapping => {
              if (mapping.category === selectedCategoryId) {
                return {
                  ...mapping,
                  columns: selectedColumns,
                  followUpAnswers,
                };
              }
              return mapping;
            }
          )
        );
      } else {
        setColumnMappings([
          ...columnMappings,
          {
            columns: selectedColumns,
            category: selectedCategoryId,
            color: selectedCategory ? selectedCategory.getColor() : "#008080",
            followUpAnswers,
          },
        ]);
      }
      setSelectedCategoryId(undefined);
      setSelectedColumns([]);
      setFollowUpAnswers({});
      setCurrentFollowUpIndex(0);
      setScreen(ColumnConfigurationScreen.SELECT_CATEGORY_OR_COLUMN);
    }
  }

  function deleteMapping(category: string) {
    setColumnMappings(
      columnMappings.filter(
        (mapping: IColumnMapping) => mapping.category !== category
      )
    );
  }

  const { primaryButton, secondaryButton } = getColumnConfigButtons(
    requiredCategoryIds,
    selectedColumns,
    selectedCategory,
    followUpQuestions,
    followUpAnswers,
    currentFollowUpIndex,
    screen,
    columnMappings,
    validationInfo,
    handleGoBackClick,
    handleNextClick,
    handleCancel,
    goToPreviousFollowUp,
    goToNextFollowUp,
    handleConfirm
  );

  return {
    selectedColumns,
    selectedCategory,
    screen,
    parsedData,
    validationInfo,
    summary,
    followUpQuestions,
    followUpAnswers,
    currentFollowUpIndex,
    primaryButton,
    secondaryButton,
    toggleColumnSelection,
    selectCategory,
    handleFollowUpAnswered,
    deleteMapping,
  };
}

/*
 * Contains all the logic for styling and wiring up actions for buttons at the bottom of column config view.
 * It's pretty horrendous. The five cases are:
 *
 * 1. No columns or categories are selected. "Go back" returns to Upload view; "Next" goes to volunteer page
 *    and is disabled if some required mappings are missing.
 *
 * 2. There are follow up questions and we're still on the category/ column selection page. "Next" will take
 *    us to the follow up questions view, and cancel deselects columns/ category.
 *
 * 3. We are on a follow up question, and there are more follow ups after this one. "Next" goes to the next
 *    follow up question; "Previous" goes back to either the previous follow up question, or the column
 *    selection view if we are already on the first one.
 *
 * 4. We are on the final follow up question. Confirming saves the mapping; "Previous" goes back to either
 *    the previous follow up question, or the column selection view if we are already on the first one.
 *
 * 5. Columns or categories are selected, and there are no follow up questions to ask. "Cancel" deselects
 *    columns and categories, and "Confirm" saves the mapping.
 */
function getColumnConfigButtons(
  requiredCategoryIds: Array<CategoryIdType>,
  selectedColumns: Array<number>,
  selectedCategory: Category<any> | undefined,
  followUpQuestions: Array<IFollowUpQuestion>,
  followUpAnswers: FollowUpAnswerMap,
  currentFollowUpIndex: number,
  screen: ColumnConfigurationScreen,
  columnMappings: Array<IColumnMapping>,
  validationInfo: IValidationInfo | undefined,
  handleGoBackClick: () => void,
  handleNextClick: () => void,
  handleCancel: () => void,
  goToPreviousFollowUp: () => void,
  goToNextFollowUp: () => void,
  handleConfirm: () => void
): { primaryButton: IButtonConfig; secondaryButton: IButtonConfig } {
  if (screen === ColumnConfigurationScreen.SELECT_CATEGORY_OR_COLUMN) {
    const missingMappings = requiredCategoryIds.filter(id => {
      return columnMappings.every(mapping => mapping.category !== id);
    });

    const missingMappingText = `Required column types have not been mapped: ${missingMappings
      .map(id => {
        const category = getCategory(id);
        return category && category.getName();
      })
      .join(", ")}`;

    return {
      primaryButton: {
        text: "Finish",
        onClick: handleNextClick,
        disabled: missingMappings.length > 0,
        primary: true,
        iconName: "arrow right",
        popupText: missingMappings.length > 0 ? missingMappingText : undefined,
      },
      secondaryButton: {
        text: "Go back",
        onClick: handleGoBackClick,
      },
    };
  } else if (
    followUpQuestions.length > 0 &&
    screen !== ColumnConfigurationScreen.ADDITIONAL_INFO
  ) {
    return {
      primaryButton: {
        text: "Next",
        onClick: goToNextFollowUp,
        disabled: !selectedCategory || selectedColumns.length === 0,
      },
      secondaryButton: {
        text: "Cancel",
        onClick: handleCancel,
      },
    };
  } else if (
    followUpQuestions.length > 0 &&
    currentFollowUpIndex < followUpQuestions.length - 1
  ) {
    return {
      primaryButton: {
        text: "Next",
        onClick: goToNextFollowUp,
        disabled: followUpAnswers[currentFollowUpIndex] === undefined,
      },
      secondaryButton: {
        text: "Previous",
        onClick: goToPreviousFollowUp,
      },
    };
  } else if (followUpQuestions.length > 0) {
    const unansweredQuestions = followUpQuestions.filter(
      q => followUpAnswers[q.id] === undefined
    );
    const isInvalid = !validationInfo || !validationInfo.isValid;
    const validationError = validationInfo && validationInfo.errorMessage;
    return {
      primaryButton: {
        text: "Confirm",
        onClick: handleConfirm,
        disabled: unansweredQuestions.length > 0 || isInvalid,
        positive: true,
        popupText: validationError,
      },
      secondaryButton: {
        text: "Previous",
        onClick: goToPreviousFollowUp,
      },
    };
  } else {
    const isInvalid = !validationInfo || !validationInfo.isValid;
    const validationError = validationInfo && validationInfo.errorMessage;
    return {
      primaryButton: {
        text: "Confirm",
        onClick: handleConfirm,
        disabled:
          !selectedCategory || selectedColumns.length === 0 || isInvalid,
        positive: true,
        popupText: validationError,
      },
      secondaryButton: {
        text: "Cancel",
        onClick: handleCancel,
      },
    };
  }
}
