import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import styled from "styled-components";
import { Home } from "./Home";
import { Nav } from "./Nav";
import { FAQ } from "./faq/FAQ";
import { Guides } from "./guides/Guides";
import { Scheduler } from "./scheduler/Scheduler";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Contact } from "./contact/Contact";

const AppContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

export function AppRouter() {
  return (
    <Router>
      <HelmetProvider>
        <AppContainer>
          <Route path="*" component={Nav} />
          <Route path="/" exact component={Home} />
          <Route path="/scheduler" component={Scheduler} />
          <Route path="/guide" component={Guides} />
          <Route path="/faq" component={FAQ} />
          <Route path="/contact" component={Contact} />
        </AppContainer>
        <Helmet>
          <title>VolSchedule</title>
          <meta
            name="description"
            content="Create volunteer schedules with ease."
          />
        </Helmet>
      </HelmetProvider>
    </Router>
  );
}
