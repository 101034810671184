import { IValidationInfo } from "../categories/types";
import {
  ITableData,
  IColumnMapping,
  AsyncData,
} from "../scheduler/store/types";
import {
  CellColorInfo,
  ITableHeaderCell,
  ITableCell,
} from "../scheduler/types";

const WHITE = "#ffffff";
const SELECTED_BACKGROUND = "#0e6eb8";
const SELECTED_HEADER_BACKGROUND = "#094d80";
const ERROR_BACKGROUND = "#e97e7e";
const ERROR_HEADER_BACKGROUND = "#db2828";

const SELECTED_STYLES = {
  background: SELECTED_BACKGROUND,
  headerBackground: SELECTED_HEADER_BACKGROUND,
  textColor: WHITE,
};

const ERROR_HEADER_STYLE = {
  textColor: WHITE,
  background: ERROR_HEADER_BACKGROUND,
};

const ERROR_CELL_STYLE = {
  textColor: WHITE,
  background: ERROR_BACKGROUND,
};

interface ColumnColorInfo extends CellColorInfo {
  headerBackground: string;
}

function getColumnStyles(
  columnMappings: Array<IColumnMapping>,
  selectedColumns: Array<number>
): { [column: number]: ColumnColorInfo } {
  const columnStyles: { [column: number]: ColumnColorInfo } = {};
  columnMappings.forEach(mapping => {
    mapping.columns.forEach(col => {
      columnStyles[col] = {
        background: `${mapping.color}aa`,
        headerBackground: mapping.color,
        textColor: WHITE,
      };
    });
  });
  selectedColumns.forEach(col => {
    columnStyles[col] = SELECTED_STYLES;
  });

  return columnStyles;
}

function getCellErrorMap(validationInfo?: IValidationInfo) {
  const cellErrorMap: {
    [column: number]: {
      [row: number]: string;
    };
  } = {};
  if (validationInfo && validationInfo.cellErrors) {
    validationInfo.cellErrors.forEach(cellError => {
      cellError.columns.forEach(column => {
        if (!cellErrorMap[column]) {
          cellErrorMap[column] = {};
        }
        cellErrorMap[column][cellError.row] = cellError.message;
      });
    });
  }

  return cellErrorMap;
}

/**
 * Pretty convoluted logic to get styles for all cells in the table.
 *
 * If the cell has errors (or any cell in the column, for headers), use
 *     error stylings and include error messages as popup text.
 * Otherwise, if the column is selected, give its cells selected styling
 * Otherwise, if the column is mapped, use the mapping color.
 * Otherwise, return just the text.
 */
export function computeTableCellInfo(
  validationInfo: IValidationInfo | undefined,
  tableData: AsyncData<ITableData>,
  columnMappings: Array<IColumnMapping>,
  selectedColumns: Array<number>
): {
  headers: ITableHeaderCell[];
  data: ITableCell[][];
} {
  if (tableData.loadState !== "LOADED") {
    return {
      headers: [],
      data: [],
    };
  }

  const columnStyles = getColumnStyles(columnMappings, selectedColumns);
  const cellErrorMap = getCellErrorMap(validationInfo);

  const headers = tableData.value.headers.map((text, i) => {
    const hasError = !!cellErrorMap[i];
    let color: CellColorInfo | undefined;
    if (hasError) {
      color = ERROR_HEADER_STYLE;
    } else if (columnStyles[i]) {
      color = {
        textColor: columnStyles[i].textColor,
        background: columnStyles[i].headerBackground,
      };
    }

    return {
      text,
      color,
      popupText: hasError
        ? "This column contains values with errors"
        : undefined,
      hasError,
    };
  });

  const data = tableData.value.data.map((row, rowIdx) =>
    row.map((text, column) => {
      const errorMessage = cellErrorMap[column] && cellErrorMap[column][rowIdx];
      let color: CellColorInfo | undefined;
      if (errorMessage) {
        color = ERROR_CELL_STYLE;
      } else if (columnStyles[column]) {
        color = {
          textColor: columnStyles[column].textColor,
          background: columnStyles[column].background,
        };
      }

      return {
        text,
        color,
        popupText: errorMessage,
      };
    })
  );

  return {
    headers,
    data,
  };
}
