import React from "react";
import styled from "styled-components";
import { Label, Icon } from "semantic-ui-react";

const LabelWrapper = styled.div`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  div.ui.label {
    margin: 0 10px;
  }
`;

interface SelectedColumnsProps {
  columns: Array<number>;
  columnNames: Array<string>;
  onRemoveColumn: (column: number) => void;
}

export const SelectedColumns: React.FC<SelectedColumnsProps> = (
  props: SelectedColumnsProps
) => {
  return (
    <LabelWrapper>
      {props.columns.map(column => {
        const columnName = props.columnNames[column];
        return (
          <Label key={columnName}>
            {columnName}
            <Icon name="delete" onClick={() => props.onRemoveColumn(column)} />
          </Label>
        );
      })}
    </LabelWrapper>
  );
};
