import { VolunteerCategory } from "./volunteerCategory";
import { AVAILABILITIES_ID, NUM_MINUTES_IN_DAY } from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IVolunteer, IAvailability } from "../../scheduler/types";
import { IParsedData, ISummary, IFollowUpQuestion } from "../types";
import { parseDateTimeColumns } from "../shared/parsers";
import { IDateTimeRange } from "../shared/types";
import { formatDateAndTimeRange, formatDate } from "../shared/dateTimeUtils";
import { validateDateTimeValues } from "../shared/validators";

function dateTimeRangeToAvailability(
  dateTimeRange: IDateTimeRange,
  id: number
): IAvailability {
  let dateStr = dateTimeRange.dayOfWeek;
  if (!dateStr) {
    if (dateTimeRange.date) {
      dateStr = formatDate(dateTimeRange.date);
    } else {
      dateStr = "";
    }
  }

  let priority = 1;
  if (
    dateTimeRange.priority &&
    dateTimeRange.priority > 0 &&
    dateTimeRange.priority < 4
  ) {
    priority = dateTimeRange.priority;
  }

  return {
    date_str: dateStr,
    start_time: dateTimeRange.range ? dateTimeRange.range.startMinuteOfDay : 0,
    end_time: dateTimeRange.range
      ? dateTimeRange.range.endMinuteOfDay
      : NUM_MINUTES_IN_DAY,
    priority,
  };
}

export class AvailabilitiesCategory extends VolunteerCategory<
  Array<IDateTimeRange>
> {
  constructor() {
    super({
      id: AVAILABILITIES_ID,
      name: "Availabile times",
      detail:
        "Select column(s) that specify the dates, days of the week, and/or times the volunteer is available.",
      color: "#A52A2A", // brown
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    return parseDateTimeColumns(data, columns, { includePriority: true });
  }

  validateParsedData(
    parsedData: IParsedData<Array<IDateTimeRange>>,
    columns: number[]
  ) {
    return validateDateTimeValues(parsedData, columns, { allowEmptyRow: true });
  }

  createSummary(parsedData: IParsedData<Array<IDateTimeRange>>): ISummary {
    let short = "";
    let label = "";
    const firstValue = parsedData.values[0][0] || {};
    if (firstValue.range) {
      if (firstValue.dayOfWeek) {
        label =
          "Volunteer availabilities with days of the week and time ranges:";
        short = "Time, day of week";
      } else if (firstValue.date) {
        label = "Volunteer availabilities with dates and time ranges:";
        short = "Time, date";
      } else {
        label = "Volunteer availabilities with time ranges only:";
        short = "Time ranges";
      }
    } else {
      if (firstValue.dayOfWeek) {
        label =
          "No time ranges found. Only the day of week will be used to match volunteers to shifts:";
        short = "Match on day of week";
      } else if (firstValue.dayOfWeek) {
        label =
          "No time ranges found. Only the date will be used to match volunteers to shifts:";
        short = "Match on date";
      }
    }

    return {
      status: "INFO",
      short,
      full: {
        label,
        summary: `Example: "${formatDateAndTimeRange(firstValue)}"`,
      },
    };
  }

  public getFollowUpQuestions(): Array<IFollowUpQuestion> {
    return [];
  }

  public mapRow(
    volunteer: IVolunteer,
    parsedRow: Array<IDateTimeRange>
  ): IVolunteer {
    return {
      ...volunteer,
      availabilities: parsedRow.map(dateTimeRangeToAvailability),
    };
  }
}
