import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  min-height: 0;
  flex: 1 1;
  background-color: white;
`;

export const Contact: React.FC = () => {
  return (
    <Wrapper>
      <Container text>
        <h1>Contact</h1>
        <p>
          VolSchedule is a project by Matt Jenny. Please email{" "}
          <a href="mailto:info@volschedule.com">info@volschedule.com</a> with
          any questions, comments, or concerns.
        </p>
      </Container>
      <Helmet>
        <title>VolSchedule | Contact</title>
        <meta name="description" content="VolSchedule contact info" />
      </Helmet>
    </Wrapper>
  );
};
