import React, { useState, useMemo } from "react";
import styled from "styled-components";
import pluralize from "pluralize";
import { Icon, Confirm } from "semantic-ui-react";
import { IColumnMapping, ITableData } from "../scheduler/store/types";
import { Category } from "../categories/category";
import { FollowUpAnswerMap } from "../categories/types";
import { CategoryIdType } from "../categories/constants";
import { getCategory } from "../categories/categoryMap";

const SummaryPanel = styled.div`
  height: 100%;
  width: 300px;
  border-left: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SummaryText = styled.span`
  margin-top: 200px;
  color: gray;
  padding: 0 20px;
  text-align: center;
`;

const SummaryItemWrapper = styled.div`
  height: 80px;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
`;

const ColorSquare = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  border: 1px solid gray;
  margin-right: 10px;
  background: ${props => props.color};
`;

const SummaryItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  cursor: pointer;
`;

const ItemPrimaryText = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const ItemSecondaryText = styled.span`
  font-size: 12px;
  color: gray;
`;

interface ISummaryItemProps {
  table: ITableData;
  columnMapping: IColumnMapping;
  category?: Category<any>;
  followUpAnswers: FollowUpAnswerMap;
  selectCategory: (id: CategoryIdType) => void;
  setMappingToDelete: (id: string | undefined) => void;
}

const SummaryItem: React.FC<ISummaryItemProps> = (props: ISummaryItemProps) => {
  const summary = useMemo(() => {
    if (!props.category) {
      return undefined;
    }

    const parsedValues = props.category.parseData(
      props.table,
      props.columnMapping.columns,
      props.followUpAnswers
    );
    const followUpQuestions = props.category.getFollowUpQuestions(
      parsedValues,
      props.columnMapping.columns
    );
    const validation = props.category.validate(
      props.table,
      parsedValues,
      props.columnMapping.columns,
      followUpQuestions,
      props.followUpAnswers
    );
    return props.category.getSummary(
      parsedValues,
      props.columnMapping.columns,
      validation,
      props.followUpAnswers
    );
  }, [props.table, props.columnMapping, props.category, props.followUpAnswers]);

  function handleDeleteClick() {
    props.setMappingToDelete(props.columnMapping.category);
  }

  function selectCategory() {
    if (props.category) {
      props.selectCategory(props.category.getId());
    }
  }

  const columnText = `${pluralize(
    "column",
    props.columnMapping.columns.length,
    true
  )}: ${props.columnMapping.columns
    .map(c => props.table.headers[c])
    .join(", ")}`;

  return (
    <SummaryItemWrapper>
      <ColorSquare color={props.columnMapping.color} />
      <SummaryItemContent onClick={selectCategory}>
        <ItemPrimaryText>
          {props.category && props.category.getName()}
        </ItemPrimaryText>
        <ItemSecondaryText>{columnText}</ItemSecondaryText>
        <ItemSecondaryText>{summary && summary.short}</ItemSecondaryText>
      </SummaryItemContent>
      <Icon name="delete" color="grey" onClick={handleDeleteClick} link />
    </SummaryItemWrapper>
  );
};

interface IColumnMappingSummaryProps {
  columnMappings: Array<IColumnMapping>;
  categoryIds: Array<CategoryIdType>;
  table: ITableData;
  selectCategory: (id: CategoryIdType) => void;
  deleteMapping: (categoryId: string) => void;
}

export const ColumnMappingSummary: React.FC<IColumnMappingSummaryProps> = (
  props: IColumnMappingSummaryProps
) => {
  const [mappingToDelete, setMappingToDelete] = useState<string | undefined>(
    undefined
  );

  function getWarningDialogName() {
    if (mappingToDelete) {
      const columnMapping = props.columnMappings.find(
        m => m.category === mappingToDelete
      );
      if (columnMapping) {
        const category = getCategory(columnMapping.category);
        return category.getName();
      }
    }
    return null;
  }

  function handleDialogCancel() {
    setMappingToDelete(undefined);
  }

  function handleConfirmDelete() {
    if (mappingToDelete) {
      props.deleteMapping(mappingToDelete);
      setMappingToDelete(undefined);
    }
  }

  return (
    <SummaryPanel>
      {props.columnMappings.map(columnMapping => (
        <SummaryItem
          key={columnMapping.category}
          columnMapping={columnMapping}
          category={getCategory(columnMapping.category)}
          followUpAnswers={columnMapping.followUpAnswers}
          selectCategory={props.selectCategory}
          setMappingToDelete={setMappingToDelete}
          table={props.table}
        />
      ))}
      <SummaryText>
        Create additional schedule constraints by selecting columns from the
        table and following directions under the table.
      </SummaryText>
      <Confirm
        open={!!mappingToDelete}
        content={`Are you sure you want to delete the column mapping for the ${getWarningDialogName()} category?`}
        onCancel={handleDialogCancel}
        onConfirm={handleConfirmDelete}
      />
    </SummaryPanel>
  );
};
