import React, { useRef, MutableRefObject } from "react";
import { Link } from "react-router-dom";
import * as Papaparse from "papaparse";
import { Container, Header, Segment, Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { Routes } from "../constants";
import { asyncLoading, asyncLoaded, asyncFailed } from "./store/util";
import { SchedulerState } from "./store/schedulerStateContainer";
import { LoadableTable } from "./LoadableTable";
import { SchedulerPage } from "./store/types";

const FirstSegment = styled(Segment)`
  &&& {
    margin-top: 20px;
  }
`;

const PrerequisiteList = styled.ol`
  li {
    margin-top: 10px;
  }
`;

const StyledBR = styled.br`
  line-height: 22px;
`;

const NextButton = styled(Button)`
  min-width: 150px;
  float: right;

  && {
    margin-bottom: 20px;
  }
`;

export const Upload: React.FC = () => {
  const shiftInputEl: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const volunteerInputEl: MutableRefObject<HTMLInputElement | null> = useRef(
    null
  );
  const {
    shiftData,
    volunteerData,
    setShiftData,
    setVolunteerData,
    setPage,
  } = SchedulerState.useContainer();

  function simulateShiftInputClick() {
    const shiftInput = shiftInputEl.current;
    if (shiftInput) {
      shiftInput.click();
    }
  }

  function simulateVolunteerInputClick() {
    const volunteerInput = volunteerInputEl.current;
    if (volunteerInput) {
      volunteerInput.click();
    }
  }

  function handleShiftFiles() {
    const shiftInput = shiftInputEl.current;
    const file =
      shiftInput &&
      shiftInput.files &&
      shiftInput.files.length > 0 &&
      shiftInput.files[0];
    if (file) {
      setShiftData(asyncLoading());
      Papaparse.parse(file, {
        complete: (result: Papaparse.ParseResult) => {
          if (result.data.length > 1) {
            setShiftData(
              asyncLoaded({
                headers: result.data[0],
                data: result.data.slice(1),
              })
            );
          } else {
            setShiftData(
              asyncFailed(
                new Error("CSV Must have a header row and additional lines.")
              )
            );
          }
        },
        error: (error: Papaparse.ParseError) => {
          setShiftData(asyncFailed(new Error(error.message)));
        },
      });
    }
  }

  function handleVolunteerFiles() {
    const volunteerInput = volunteerInputEl.current;
    const file =
      volunteerInput &&
      volunteerInput.files &&
      volunteerInput.files.length > 0 &&
      volunteerInput.files[0];
    if (file) {
      setVolunteerData(asyncLoading());
      Papaparse.parse(file, {
        complete: (result: Papaparse.ParseResult) => {
          if (result.data.length > 1) {
            setVolunteerData(
              asyncLoaded({
                headers: result.data[0],
                data: result.data.slice(1),
              })
            );
          } else {
            setVolunteerData(
              asyncFailed(
                new Error("CSV Must have a header row and additional lines.")
              )
            );
          }
        },
        error: (error: Papaparse.ParseError) => {
          setVolunteerData(asyncFailed(new Error(error.message)));
        },
      });
    }
  }

  function handleNextClick() {
    setPage(SchedulerPage.SHIFTS);
    window.scrollTo(0, 0);
  }

  return (
    <Container>
      <FirstSegment vertical>
        <Header as="h1">Let's get started.</Header>
        <p>
          First, please make sure you have thoroughly read over the
          prerequisites below. For more details on how to make the most of this
          tool, please take a look at the <Link to={Routes.GUIDES}>guide</Link>.
        </p>
      </FirstSegment>

      <Segment vertical>
        <Header as="h2">Prerequisites</Header>
        <PrerequisiteList>
          <li>
            This scheduling tool requires you to upload{" "}
            <strong>two spreadsheets</strong>: one with information about open
            volunteer <strong>shifts</strong>, and one with information about
            <strong> volunteers</strong>. Each row in the shifts spreadsheet
            should correspond to one shift at a project with open spots for
            volunteers, and each row in the volunteers table should correspond
            to a single volunteer.
          </li>
          <li>
            You should have the spreadsheets on your computer in comma-separated
            value (CSV) format.
            <StyledBR />
            If the spreadsheet is currently in excel, open the{" "}
            <strong>File</strong> menu and select <strong>Save as</strong>.
            Then, change the file format to the{" "}
            <strong>"CSV (comma delimited)"</strong> or{" "}
            <strong>"Comma Separated Values (.csv)"</strong> option.
            <StyledBR />
            If the spreadsheet is in Google Sheets, click the{" "}
            <strong>File</strong> menu, then click <strong>Download as</strong>,
            and select <strong>".csv"</strong>.
          </li>
          <li>The first row of each spreadsheet must contain column names.</li>
          <li>
            There may not be any blank rows, or any values in the spreadsheets
            other than rows with information about shifts or volunteers.
          </li>
          <li>
            Each shift must at minimum include a <strong>site</strong>, or
            location name, and a summary of the time or times for the shift.
            Please see the <Link to={Routes.GUIDES}>guide </Link>
            for more details about how to properly format dates and times before
            using this tool.
          </li>
          <li>
            Each volunteer must at minimum have a unique identifier, such as
            email or phone number, and a summary of the time or times that they
            are available. Please see the <Link to={Routes.GUIDES}>guide </Link>
            for more details about how to properly format dates and times before
            using this tool.
          </li>
          <li>
            We don't store your data on our servers, but it is recommended that
            you remove highly sensitive data (SSNs, passwords, etc) from your
            spreadsheets before uploading them. We're not evil, but as good
            citizens of the internet, we think it's always worth taking extra
            steps to protect your volunteers' data.
          </li>
        </PrerequisiteList>
      </Segment>

      <Segment vertical>
        <Header as="h2">Upload files</Header>
        <Header as="h4">1. Shifts</Header>
        <p>
          Please select a .csv file containing data about each open volunteer
          shift, including site name, dates, and times.
        </p>
        <input
          type="file"
          accept=".csv"
          onChange={handleShiftFiles}
          ref={shiftInputEl}
        />
        <LoadableTable
          asyncData={shiftData}
          placeholder="Click to upload a CSV file of open shifts."
          onClick={
            shiftData.loadState !== "LOADED"
              ? simulateShiftInputClick
              : undefined
          }
        />
        <Header as="h4">2. Volunteers</Header>
        <p>
          Please select a .csv file containing data about each available
          volunteer, including a unique identifier and dates/ times available.
        </p>
        <input
          type="file"
          accept=".csv"
          onChange={handleVolunteerFiles}
          ref={volunteerInputEl}
        />
        <LoadableTable
          asyncData={volunteerData}
          placeholder="Click to upload a CSV file of volunteers."
          onClick={
            volunteerData.loadState !== "LOADED"
              ? simulateVolunteerInputClick
              : undefined
          }
        />
      </Segment>

      <Segment vertical>
        <NextButton
          primary
          disabled={
            shiftData.loadState !== "LOADED" ||
            volunteerData.loadState !== "LOADED"
          }
          onClick={handleNextClick}
        >
          Next
          <Icon name="arrow right" />
        </NextButton>
      </Segment>
    </Container>
  );
};
