import pluralize from "pluralize";
import { VolunteerCategory } from "./volunteerCategory";
import { PREFERRED_SITES_CATEGORY_ID } from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IVolunteer } from "../../scheduler/types";
import { IParsedData, ISummary, IFollowUpQuestion } from "../types";

export class PreferredSitesCategory extends VolunteerCategory<Array<string>> {
  constructor() {
    super({
      id: PREFERRED_SITES_CATEGORY_ID,
      name: "Preferred sites",
      detail: "Select column(s) that specify site requests.",
      color: "#A0A0A0", // grey
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    return {
      values: data.data.map((row: string[]) => {
        const sites: string[] = [];
        columns.forEach(col => {
          sites.push(
            ...row[col]
              .split(",")
              .map(val => val.trim().toLowerCase())
              .filter(val => val !== "")
          );
        });
        return sites;
      }),
    };
  }

  validateParsedData() {
    return { isValid: true };
  }

  createSummary(parsedData: IParsedData<Array<string>>): ISummary {
    const siteCounts: { [site: string]: number } = {};
    parsedData.values.forEach(sites => {
      sites.forEach(site => {
        if (siteCounts[site]) {
          siteCounts[site] = siteCounts[site] + 1;
        } else {
          siteCounts[site] = 1;
        }
      });
    });

    const uniqueSites = Object.keys(siteCounts);
    uniqueSites.sort((a, b) => siteCounts[b] - siteCounts[a]);
    let summary = "";
    if (uniqueSites.length === 1) {
      summary = uniqueSites[0];
    } else if (uniqueSites.length === 2) {
      summary = `${uniqueSites[0]} (${siteCounts[uniqueSites[0]]}) and ${
        uniqueSites[1]
      } (${siteCounts[uniqueSites[1]]})`;
    } else if (uniqueSites.length > 2) {
      summary =
        `${uniqueSites[0]} (${siteCounts[uniqueSites[0]]}), ${
          uniqueSites[1]
        } (${siteCounts[uniqueSites[1]]}),` +
        ` + ${uniqueSites.length - 2} more`;
    }

    return {
      status: "INFO",
      short: pluralize("site", uniqueSites.length, true),
      full: {
        label: pluralize("site", uniqueSites.length, true),
        summary,
      },
    };
  }

  public getFollowUpQuestions(): Array<IFollowUpQuestion> {
    return [];
  }

  public mapRow(
    volunteer: IVolunteer,
    site_requests: Array<string>
  ): IVolunteer {
    return {
      ...volunteer,
      site_requests,
    };
  }
}
