import pluralize from "pluralize";
import { VolunteerCategory } from "./volunteerCategory";
import { GROUP_CATEGORY_ID } from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IVolunteer } from "../../scheduler/types";
import { IParsedData, ISummary, IFollowUpQuestion } from "../types";

export class GroupCategory extends VolunteerCategory<string> {
  constructor() {
    super({
      id: GROUP_CATEGORY_ID,
      name: "Groups",
      detail: "Select column that specifies groups.",
      color: "#32CD32", // olive
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    return {
      values: data.data.map((row: string[]) => {
        if (columns.length === 1) {
          return row[columns[0]].trim().toLowerCase();
        } else {
          return "";
        }
      }),
    };
  }

  validateParsedData(_parsedData: IParsedData<string>, columns: number[]) {
    if (columns.length > 2) {
      return {
        isValid: false,
        errorMessage: "Only one column may be selected for groups.",
      };
    }
    return { isValid: true };
  }

  createSummary(parsedData: IParsedData<string>): ISummary {
    const groupCounts: { [group: string]: number } = {};
    parsedData.values.forEach(group => {
      if (group) {
        if (groupCounts[group]) {
          groupCounts[group] = groupCounts[group] + 1;
        } else {
          groupCounts[group] = 1;
        }
      }
    });

    const uniqueGroups = Object.keys(groupCounts);
    uniqueGroups.sort((a, b) => groupCounts[b] - groupCounts[a]);
    let summary = "";
    if (uniqueGroups.length === 1) {
      summary = uniqueGroups[0];
    } else if (uniqueGroups.length === 2) {
      summary = `${uniqueGroups[0]} (${groupCounts[uniqueGroups[0]]}) and ${
        uniqueGroups[1]
      } (${groupCounts[uniqueGroups[1]]})`;
    } else if (uniqueGroups.length > 2) {
      summary =
        `${uniqueGroups[0]} (${groupCounts[uniqueGroups[0]]}), ${
          uniqueGroups[1]
        } (${groupCounts[uniqueGroups[1]]}),` +
        ` + ${uniqueGroups.length - 2} more`;
    }

    return {
      status: "INFO",
      short: pluralize("group", uniqueGroups.length, true),
      full: {
        label: pluralize("group", uniqueGroups.length, true),
        summary,
      },
    };
  }

  public getFollowUpQuestions(): Array<IFollowUpQuestion> {
    return [];
  }

  public mapRow(volunteer: IVolunteer, group: string): IVolunteer {
    return {
      ...volunteer,
      group,
    };
  }
}
