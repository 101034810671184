import React from "react";
import { Popup, Button } from "semantic-ui-react";
import styled from "styled-components";
import { IColumnMapping } from "../scheduler/store/types";
import { CategoryIdType } from "../categories/constants";
import { getCategory } from "../categories/categoryMap";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px;
`;

const CategoryItem = styled.div`
  margin: 5px 20px;
  text-align: center;
  button {
    width: 150px;
  }
`;

export interface ColumnCategoriesProps {
  categoryIds: Array<CategoryIdType>;
  requiredCategories: Array<CategoryIdType>;
  columnMappings: Array<IColumnMapping>;
  selectedCategoryId?: string;
  onSelectCategoryId: (categoryId: string) => void;
}

export const ColumnCategories: React.FC<ColumnCategoriesProps> = (
  props: ColumnCategoriesProps
) => {
  return (
    <Wrapper>
      {props.categoryIds.map(categoryId => {
        const category = getCategory(categoryId);
        const isRequired = props.requiredCategories.some(
          id => id === categoryId
        );
        const isMapped = props.columnMappings.some(
          m => m.category === categoryId
        );
        return (
          <CategoryItem key={categoryId}>
            <Popup
              content={`${category.getDetail()}${
                isRequired ? " (Required)" : ""
              }`}
              trigger={
                <Button
                  basic={!isMapped && categoryId !== props.selectedCategoryId}
                  onClick={() => props.onSelectCategoryId(categoryId)}
                  primary={!isMapped || categoryId === props.selectedCategoryId}
                >
                  {category.getName()}
                  {isRequired ? " *" : ""}
                </Button>
              }
            />
          </CategoryItem>
        );
      })}
    </Wrapper>
  );
};
