import { saveAs } from "file-saver";
import * as Papaparse from "papaparse";
import React, { MutableRefObject, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Input, Message } from "semantic-ui-react";
import styled from "styled-components";
import { EXAMPLE_SHIFT_DATA, EXAMPLE_VOLUNTEER_DATA } from "./constants";
import {
  parseDateTime,
  formatDateAndTimeRange,
} from "../categories/shared/dateTimeUtils";
import uploadImage from "../images/tutorial_upload_1.png";
import shiftConfigurationImg1 from "../images/shift_configuration_1.png";
import shiftConfigurationImg2 from "../images/shift_configuration_2.png";
import volunteerConfigurationImg1 from "../images/volunteer_configuration_1.png";
import volunteerConfigurationImg2 from "../images/volunteer_configuration_2.png";
import generatedScheduleImg from "../images/generated_schedule.png";

const StyledContainer = styled.div`
  padding: 40px 0 40px 0;
  display: flex;
  min-height: 0;
  flex: 1 1;
  background-color: white;
`;

const NavSidebar = styled.div`
  width: 350px;
  min-width: 350px;
  flex: 0 0;
  padding-left: 40px;

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    font-size: 18px;
    line-height: 30px;
    li > ul {
      padding-left: 20px;
      font-size: 16px;
    }
  }

  a {
    cursor: pointer;
  }
`;

const MainContent = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-right: 20px;
  flex: 1 1;
`;

const TryItInfo = styled.div`
  padding: 10px 0;
  span.error {
    color: red;
  }
`;

const TextWrapper = styled.div`
  height: 100%;
  max-width: 700px;
`;

const DownloadLink = styled.a`
  cursor: pointer;
`;

type ParsedDateTime = {
  value?: string;
  error?: string;
};

export const Guides: React.FC = () => {
  const overviewRef: MutableRefObject<HTMLHeadingElement | null> = useRef(null);
  const formatRef: MutableRefObject<HTMLHeadingElement | null> = useRef(null);
  const shiftCategoriesRef: MutableRefObject<HTMLHeadingElement | null> = useRef(
    null
  );
  const volunteerCategoriesRef: MutableRefObject<HTMLHeadingElement | null> = useRef(
    null
  );
  const tutorialRef: MutableRefObject<HTMLHeadingElement | null> = useRef(null);
  const tutorialSetupRef: MutableRefObject<HTMLHeadingElement | null> = useRef(
    null
  );
  const tutorialUploadRef: MutableRefObject<HTMLHeadingElement | null> = useRef(
    null
  );
  const tutorialShiftsRef: MutableRefObject<HTMLHeadingElement | null> = useRef(
    null
  );
  const tutorialVolunteersRef: MutableRefObject<HTMLHeadingElement | null> = useRef(
    null
  );
  const tutorialGenerateRef: MutableRefObject<HTMLHeadingElement | null> = useRef(
    null
  );

  const [parsedDateTime, setParsedDateTime] = useState<ParsedDateTime>({});

  function downloadExampleData(data: string[][], filename: string) {
    const csvText = Papaparse.unparse(data);
    const fileBlob = new Blob([csvText], { type: "text/csv;charset=utf-8" });
    saveAs(fileBlob, filename);
  }

  function downloadExampleShiftData() {
    downloadExampleData(EXAMPLE_SHIFT_DATA, "example_shifts.csv");
  }

  function downloadExampleVolunteerData() {
    downloadExampleData(EXAMPLE_VOLUNTEER_DATA, "example_volunteers.csv");
  }

  function scrollTo(ref: MutableRefObject<HTMLHeadingElement | null>) {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleDateTimeInput(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const parsed = value && parseDateTime(value);
    const formatted = parsed && formatDateAndTimeRange(parsed);
    if (parsed && formatted) {
      setParsedDateTime({
        value: formatted,
        error: parsed.error && parsed.error.message,
      });
    } else if (value) {
      setParsedDateTime({
        error: "Failed to parse date and/or time.",
      });
    } else {
      setParsedDateTime({});
    }
  }

  return (
    <StyledContainer>
      <NavSidebar>
        <ul>
          <li>
            <a onClick={() => scrollTo(overviewRef)} href="#Overview">
              Overview
            </a>
          </li>
          <li>
            <a onClick={() => scrollTo(formatRef)} href="#Formatting your data">
              Formatting your data
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollTo(shiftCategoriesRef)}
              href="#Shift Categories"
            >
              Shift Categories
            </a>
          </li>
          <li>
            <a
              onClick={() => scrollTo(volunteerCategoriesRef)}
              href="#Volunteer Categories"
            >
              Volunteer Categories
            </a>
          </li>
          <li>
            <a onClick={() => scrollTo(tutorialRef)} href="#Tutorial">
              Tutorial
            </a>
            <ul>
              <li>
                <a onClick={() => scrollTo(tutorialSetupRef)} href="#Tutorial">
                  Setup
                </a>
              </li>
              <li>
                <a onClick={() => scrollTo(tutorialUploadRef)} href="#Tutorial">
                  Uploading your data
                </a>
              </li>
              <li>
                <a onClick={() => scrollTo(tutorialShiftsRef)} href="#Tutorial">
                  Configuring Shift columns
                </a>
              </li>
              <li>
                <a
                  onClick={() => scrollTo(tutorialVolunteersRef)}
                  href="#Tutorial"
                >
                  Configuring Volunteer columns
                </a>
              </li>
              <li>
                <a
                  onClick={() => scrollTo(tutorialGenerateRef)}
                  href="#Tutorial"
                >
                  Generating a schedule
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </NavSidebar>
      <MainContent>
        <TextWrapper>
          <h1>Guide</h1>
          <h2 ref={overviewRef}>Overview</h2>
          <p>
            VolSchedule was created to make it as easy as possible for volunteer
            coordinators to schedule volunteers to shifts based on their own
            unique schedules and preferences. It is particularly well-suited for
            cases where many volunteers apply at once and need to be assigned
            projects that fit their limited availability.
            <br />
            <br />
            When you open the scheduler, the first thing you'll be prompted to
            do is to upload your volunteer data and shift data. You should
            already have a spreadsheet of the volunteers that you're trying to
            schedule, and another spreadsheet of the shifts or projects to which
            the volunteers will be assigned. You will need to export these
            spreadsheets as CSV (comma-separated value) files following the
            instructions on the screen. Once they are in the right format, click
            to upload the files and continue.
            <br />
            <br />
            Next, we'll ask you to tell us a bit more about your data. Every
            volunteer coordinator sets up their spreadsheet in a slightly
            different way, so we'll need your help to figure out what each
            column means. You will be prompted to select one or more columns and
            then pick one of the categories that we support -- more on that
            below -- to tell us what those columns mean. First you'll enter
            column information for the Shifts spreadsheet, and then for
            Volunteers. If you discover that you need to make changes to the
            data itself, you should change the data in your original
            spreadsheet, export it to a CSV file again, and go back to the first
            screen to re-upload it.
            <br />
            <br />
            Last, once you've made sure all the information looks accurate,
            you'll be able to generate a schedule. This process usually takes 10
            to 15 seconds if there are a lot of volunteers. We'll do our best to
            fill as many shifts and schedule as many volunteers as possible,
            while respecting volunteers' schedules and weighting any volunteer
            preferences or priorities that you've indicated.
            <br />
            <br />
            We encourage you to think of the generated schedule as a first
            draft. Managing and scheduling volunteers is a deeply human
            endeavor, and robots can only get us so far. You know these
            volunteers and projects best, and so you'll likely spot ways to
            improve upon the schedule. Our hope is that VolSchedule can make a
            good enough schedule that you can spend your time making sure
            everyone is set up to succeed, rather than spending your time trying
            to shift time slots around to free up two more volunteers on Tuesday
            afternoon.
          </p>
          <h2 ref={formatRef}>Formatting your data</h2>
          <p>
            You should have your data in two spreadsheets: one for volunteers,
            and one for shifts to which they will be assigned. Each spreadsheet
            should have a header row with column names, one row per volunteer or
            shift, and nothing else. Unused columns are acceptable and will not
            affect the schedule that is generated.
            <br />
            <br />
            You can label the columns anything you'd like, but we will need to
            figure out what the data in each column represents. We will prompt
            you to categories to your shift and volunteer columns.
            <br />
            <br />
            The dates and time slots are likely the most challenging data types
            to format. VolSchedule accepts date/time values with days of the
            week or with dates, combined with time ranges. Days of the week may
            be abbreviated, and dates must be in the format "DD/MM/YYYY".
            Examples of valid datetime values are "Monday 10:00am-12:00pm",
            "Tues 4-5", or "04/12/2020 11:00-1:00". If am/pm values are not
            specified, we will make our best guess, but you are strongly
            encouraged to specify "am" or "pm" with your times to avoid
            unexpected results. Time ranges may not span multiple days.
          </p>
          <Message info>
            <Message.Header>Try it!</Message.Header>
            <div>
              Test out your date and time values to check if they are formatted
              correctly.
            </div>
            <br />
            <Input
              fluid
              onChange={handleDateTimeInput}
              placeholder="Enter a date/time value..."
            />
            <TryItInfo>
              <span>{parsedDateTime.value}</span>
              <br />
              <span className="error">{parsedDateTime.error}</span>
            </TryItInfo>
          </Message>
          <h2 ref={shiftCategoriesRef}>Shift categories</h2>
          <p>
            <strong>Site/ Location (required): </strong>One or more columns that
            identify which project or location volunteers will be assigned to.
            These columns will appear in the generated schedule.
            <br />
            <br />
            <strong>Date/ Time (required): </strong>Column(s) that specify when
            volunteers must be available to be assigned to each shift. If
            multiple columns are selected, you can specify whether volunteers
            should be available for ALL of the times, or if we should pick just
            one time out of the options. See{" "}
            <a onClick={() => scrollTo(formatRef)} href="#Formatting your data">
              Formatting your data
            </a>{" "}
            for more on how to format these values.
            <br />
            <br />
            <strong>Volunteer count: </strong>Used to specify a minimum and/or
            maxiumum number of volunteers for each shift. Can either be a single
            column with a min or max value, two columns with a min and a max
            value, or a single column with a range (e.g. "5-7").
            <br />
            <br />
            <strong>Required roles: </strong>Used to specify any roles that all
            or some volunteers in the shift must have. Required roles are handy
            if you need a driver, two leaders, or all volunteers to have
            completed a training. Roles should be entered as a comma-separated
            list or selected from multiple columns, and may optionally specify
            the number of volunteers required for each role. Names of roles must
            EXACTLY match the roles in the volunteers spreadsheet. Example:
            ("Driver, CPR certified(2)").
          </p>
          <h2 ref={volunteerCategoriesRef}>Volunteer categories</h2>
          <p>
            <strong>Unique ID (required): </strong>One or more columns that
            uniquely identify each volunteer. These columns will appear in the
            generated schedule, so you are encouraged to include all data that
            you wish to be included in the final, generated schedule.
            <br />
            <br />
            <strong>Available times (required): </strong>Column(s) that specify
            time(s) when the volunteers are available to serve. See{" "}
            <a onClick={() => scrollTo(formatRef)} href="#Formatting your data">
              Formatting your data
            </a>{" "}
            for more on how to format these values.
            <br />
            <br />
            <strong>Priority: </strong>A number indicating high priority (1),
            medium priority (2), or low priority (3) for each volunteer. These
            may be added by a volunteer coordinator based on how long each
            volunteer has been serving or based on any other criteria. This will
            not add a hard requirement to the schedule, but we'll try to
            prioritize higher-priority volunteers whenever possible.
            <br />
            <br />
            <strong>Roles: </strong>Column(s) representing roles the volunteer
            has. If used, these must match the roles from the Shifts spreadsheet
            exactly.
            <strong>Preferred sites: </strong>Specify sites at which volunteers
            specifically wish to volunteer. This should match the columns
            selected for "Site / Location" joined by spaces and dashes; for
            example, if you select two columns with value "Anytown High School"
            and "Ms. Smith", the corresponding preferred site would be "Anytown
            High School - Ms. Smith".
            <strong>Groups: </strong>If volunteers are part of a group and must
            be assigned along with the others from the group, the group name may
            be specified here. The group name must match the group name
            specified for other volunteers in the group.
          </p>
          <h2 ref={tutorialRef}>Tutorial</h2>
          <h3 ref={tutorialSetupRef}>Setup</h3>
          <p>
            This tutorial will use example data with ten K-12 classrooms that
            need weekly volunteers and a number of volunteers who are available
            at various times. Click{" "}
            <DownloadLink onClick={downloadExampleShiftData}>here</DownloadLink>{" "}
            to download the shift data for this tutorial, and click{" "}
            <DownloadLink onClick={downloadExampleVolunteerData}>
              here
            </DownloadLink>{" "}
            to download the volunteer data.
          </p>
          <h3 ref={tutorialUploadRef}>Uploading your data</h3>
          Once you have downloaded the files above, navigate to the "Scheduler"
          tab. You can keep referring back to this Guide as you go along without
          losing your work. Keep in mind that when working with your own Excel
          or Google Sheets data, you'll need to export the spreadsheets as a CSV
          before you can upload them.
          <br />
          <br />
          In the Scheduler, you'll see a set of prerequisites. Scroll down and
          you will see two inputs prompting you to click and upload your Shifts
          and Volunteers CSV files. Once you have uploaded them, you will see
          the data appear in the blank space below the upload button. Once both
          files are uploaded, click "Next" in the bottom right to proceed to
          Shift configuration.
          <br />
          <br />
          <img
            src={uploadImage}
            width={700}
            alt="Screenshot of uploading spreadsheets"
          />
          <h3 ref={tutorialShiftsRef}>Configuring Shift columns</h3>
          Next, we'll give VolSchedule more information about our data. Starting
          with the first category, we'll select all columns that describe the
          site and/or location of the shift. In our case, both the "School" and
          "Class" columns indicate where each shift will be held, so we'll begin
          by clicking each of those columns. Then click the "Site/ Location"
          button below, and check that the summary tells us that there are 10
          unique sites. Press "Confirm" to finish categorizing the sites.
          <br />
          <br />
          <img
            src={shiftConfigurationImg1}
            width={700}
            alt="Screenshot of columns being selected"
          />
          <br />
          <br />
          We will repeat that process for each of the remaining categories.
          Select the "Time 1" and "Time 2" columns for the "Date/ Time"
          category, and when prompted, select that only one of the listed times
          should be assigned as the shift time. You should now see two
          categories in the right-hand summary view. Select "Volunteer count"
          for the "Max Volunteers" category and "Required Roles" for "Roles" (we
          only need one driver for each shift). You should now have a screen
          that looks like this, and are ready to click "Finish" to move on to
          Volunteers.
          <br />
          <br />
          <img
            src={shiftConfigurationImg2}
            width={700}
            alt="Shift columns mapped to categories"
          />
          <h3 ref={tutorialVolunteersRef}>Configuring Volunteer columns</h3>
          Just like we did for shifts, we'll need to assign categories for the
          columns in our volunteer data. Try selecting the "Name" column for the
          "Unique ID" category, and you'll see an error pop up:
          <br />
          <br />
          <img
            src={volunteerConfigurationImg1}
            width={700}
            alt="Error message about non-unique IDs"
          />
          <br />
          <br />
          There are two John Adams in our volunteer spreadsheet, so that column
          alone does not uniquely identify the volunteer! Fortunately, we also
          have email addresses for each volunteer, and we can add that column to
          our selection to make each row unique. If you hit errors like this
          when configuring your data, you may be able to change your selection
          to solve the problem, or you may need to fix your spreadsheet and
          re-upload the data.
          <br />
          <br />
          Continue assigning categories to the remaining columns -- Availability
          for the two day/time columns, and Priority and Roles to their
          respectively named categories -- and click "Finish" once all columns
          have an assigned category.
          <br />
          <br />
          <img
            src={volunteerConfigurationImg2}
            width={700}
            alt="Volunteer columns mapped to categories"
          />
          <h3 ref={tutorialGenerateRef}>Generating a schedule</h3>
          It's finally time to generate our schedule! Click "Generate Schedule"
          to find the optimal schedule for our volunteers within the constraints
          we've provided. Once it's been generated, we can download the results,
          or regenerate the schedule if we decide to change any of our category
          selections. In this case, we've been able to assign 36 out of 44
          volunteers to a shift. It's not bad, but as in most real scenarios,
          there is no perfect way to assign everyone to one of the limited time
          slots.
          <br />
          <br />
          <img
            src={generatedScheduleImg}
            width={700}
            alt="A generated schedule"
          />
          <br />
          <br />
          Please keep in mind that the schedule you generate is intended to be a
          "first draft" of your volunteer schedule. We encourage you to look
          over the new schedule you've downloaded and edit to your heart's
          content.
          <br />
          <br />
          Lastly, we'd love to hear from you! Please send any questions or
          comments to{" "}
          <a href="mailto:info@volschedule.com">info@volschedule.com</a>.
        </TextWrapper>
      </MainContent>
      <Helmet>
        <title>VolSchedule | Guide</title>
        <meta
          name="description"
          content="Learn how to make the most of VolSchedule."
        />
      </Helmet>
    </StyledContainer>
  );
};
