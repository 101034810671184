import pluralize from "pluralize";
import { ShiftCategory } from "./shiftCategory";
import {
  NUM_VOLUNTEERS_TYPE_ID,
  MIN,
  MAX,
  NUM_VOLUNTEERS_ID,
} from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IShift } from "../../scheduler/types";
import {
  IParsedData,
  ISummary,
  IFollowUpQuestion,
  ICellError,
  NumberRange,
  FollowUpAnswerMap,
  IValidationInfo,
} from "../types";

const rangeRegex = /^([0-9]+)\s*(?:-|to)([0-9]+)$/i;

export class NumVolunteersCategory extends ShiftCategory<NumberRange> {
  constructor() {
    super({
      id: NUM_VOLUNTEERS_ID,
      name: "Volunteer count",
      detail:
        "Select one or two columns that specify the minimum and/or maximum number of volunteers per shift.",
      color: "#016936", // green
    });
  }

  public parseData(
    data: ITableData,
    columns: number[],
    followUpAnswers: FollowUpAnswerMap
  ) {
    if (columns.length === 1) {
      return this.parseSingleColumn(data, columns, followUpAnswers);
    } else if (columns.length === 2) {
      return this.parseTwoColumns(data, columns);
    } else {
      return {
        values: data.data.map(() => ({})),
      };
    }
  }

  validateParsedData(
    parsedData: IParsedData<NumberRange>,
    columns: number[]
  ): IValidationInfo {
    if (columns.length === 0 || columns.length > 2) {
      return {
        isValid: false,
        errorMessage: "One or two columns must be selected.",
      };
    }

    let isRange: boolean;
    const cellErrors: Array<ICellError> = [];
    parsedData.values.forEach((range, row) => {
      if (
        range.min !== undefined &&
        range.max !== undefined &&
        range.min > range.max
      ) {
        cellErrors.push({
          row,
          columns,
          message: `Min value (${range.min}) is greater than max value (${range.max}).`,
        });
      } else if (
        columns.length === 1 &&
        (range.min !== undefined || range.max !== undefined)
      ) {
        const currentIsRange =
          range.min !== undefined && range.max !== undefined;
        if (isRange === undefined) {
          isRange = currentIsRange;
        } else if (isRange !== currentIsRange) {
          cellErrors.push({
            row,
            columns,
            message: "Values must all be integers or all be number ranges.",
          });
        }
      }
    });

    return {
      isValid: cellErrors.length === 0,
      errorMessage: cellErrors.length > 0 ? "Errors found" : undefined,
      cellErrors,
    };
  }

  createSummary(parsedData: IParsedData<NumberRange>): ISummary {
    const numMin = parsedData.values.filter(range => range.min !== undefined)
      .length;
    const numMax = parsedData.values.filter(range => range.max !== undefined)
      .length;

    let short = "No values found.";
    let summaryMinPart = numMin
      ? `${pluralize("row", numMin, true)} with a minimum number of volunteers`
      : "";
    let summaryMaxPart = numMax
      ? `${pluralize("row", numMax, true)} with a maximum number of volunteers`
      : "";
    const summary = `${summaryMinPart}${
      numMin && numMax ? " and" : ""
    } ${summaryMaxPart}.`;
    if (numMin && numMax) {
      short = "Minimum and maximum numbers of volunteers per shift.";
    } else if (numMin) {
      short = "Minimum number of volunteers per shift.";
    } else if (numMax) {
      short = "Maximum number of volunteers per shift.";
    }

    return {
      status: "INFO",
      short,
      full: {
        label: short,
        summary,
      },
    };
  }

  public getFollowUpQuestions(
    parsedData: IParsedData<NumberRange>
  ): Array<IFollowUpQuestion> {
    if (parsedData.values.some(range => !!range.singleValue)) {
      return [
        {
          id: NUM_VOLUNTEERS_TYPE_ID,
          questionText:
            "Does this column represent a minimum or maximum number of volunteers?",
          options: [
            {
              label: "Minimum",
              value: MIN,
            },
            {
              label: "Maximum",
              value: MAX,
            },
          ],
        },
      ];
    }
    return [];
  }

  public mapRow(shift: IShift, parsedRow: NumberRange): IShift {
    return {
      ...shift,
      max_volunteers: parsedRow.max,
      min_volunteers: parsedRow.min,
    };
  }

  private parseSingleColumn(
    data: ITableData,
    columns: number[],
    followUpAnswers: FollowUpAnswerMap
  ) {
    const parsingErrors: Array<ICellError> = [];
    const values: Array<NumberRange> = [];

    data.data.forEach((row, rowIdx) => {
      const match = rangeRegex.exec(row[columns[0]].trim());
      if (match) {
        try {
          const min = Number.parseInt(match[1], 10);
          const max = Number.parseInt(match[2], 10);
          values.push({ min, max });
        } catch {
          values.push({});
          parsingErrors.push({
            row: rowIdx,
            columns,
            message: "Failed to parse min/max values as numbers.",
          });
          return;
        }
      } else {
        try {
          const val = Number.parseInt(row[columns[0]].trim());
          if (followUpAnswers[NUM_VOLUNTEERS_TYPE_ID]) {
            if (followUpAnswers[NUM_VOLUNTEERS_TYPE_ID] === MIN) {
              values.push({ min: val, singleValue: val });
            } else {
              values.push({ max: val, singleValue: val });
            }
          } else {
            values.push({ singleValue: val });
          }
        } catch {
          values.push({});
          parsingErrors.push({
            row: rowIdx,
            columns,
            message: "Failed to parse as a number or range.",
          });
        }
      }
    });

    return {
      values,
      parsingErrors,
    };
  }

  private parseTwoColumns(data: ITableData, columns: number[]) {
    const parsingErrors: Array<ICellError> = [];
    const values: Array<NumberRange> = [];
    const sortedColumns = [...columns];
    sortedColumns.sort();

    data.data.forEach((row, rowIdx) => {
      try {
        const min = Number.parseInt(row[sortedColumns[0]].trim());
        const max = Number.parseInt(row[sortedColumns[1]].trim());
        values.push({ min, max });
      } catch {
        values.push({});
        parsingErrors.push({
          row: rowIdx,
          columns,
          message: "Failed to parse min/max values as numbers.",
        });
        return;
      }
    });

    return {
      values,
      parsingErrors,
    };
  }
}
