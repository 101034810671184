import { Category } from "./category";
import {
  CategoryIdType,
  SITE_ID,
  SHIFT_DATETIME_ID,
  VOLUNTEER_UNIQUE_ID,
  AVAILABILITIES_ID,
  NUM_VOLUNTEERS_ID,
  ROLES_ID,
  PRIORITY_ID,
  REQUIRED_ROLES_ID,
  PREFERRED_SITES_CATEGORY_ID,
  GROUP_CATEGORY_ID,
} from "./constants";
import { SiteCategory } from "./shifts/siteCategory";
import { ShiftDateTimeCategory } from "./shifts/shiftDateTimeCategory";
import { UniqueIdCategory } from "./volunteers/uniqueIdCategory";
import { AvailabilitiesCategory } from "./volunteers/availabilitiesCategory";
import { NumVolunteersCategory } from "./shifts/numVolunteersCategory";
import { RolesCategory } from "./volunteers/rolesCategory";
import { PriorityCategory } from "./volunteers/priorityCategory";
import { RequiredRolesCategory } from "./shifts/requiredRolesCategory";
import { PreferredSitesCategory } from "./volunteers/preferredSitesCategory";
import { GroupCategory } from "./volunteers/groupCategory";

export const categoryMap: { [key in CategoryIdType]: Category<any> } = {
  [SITE_ID]: new SiteCategory(),
  [SHIFT_DATETIME_ID]: new ShiftDateTimeCategory(),
  [NUM_VOLUNTEERS_ID]: new NumVolunteersCategory(),
  [REQUIRED_ROLES_ID]: new RequiredRolesCategory(),
  [VOLUNTEER_UNIQUE_ID]: new UniqueIdCategory(),
  [AVAILABILITIES_ID]: new AvailabilitiesCategory(),
  [ROLES_ID]: new RolesCategory(),
  [PRIORITY_ID]: new PriorityCategory(),
  [PREFERRED_SITES_CATEGORY_ID]: new PreferredSitesCategory(),
  [GROUP_CATEGORY_ID]: new GroupCategory(),
};

export function getCategory(id: CategoryIdType) {
  if (!categoryMap[id]) {
    throw new Error(`Unrecognized category id "${id}."`);
  }

  return categoryMap[id];
}
