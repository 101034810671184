import pluralize from "pluralize";
import { ShiftCategory } from "./shiftCategory";
import { SITE_ID } from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IShift } from "../../scheduler/types";
import { IParsedData, ISummary, IFollowUpQuestion, ICellError } from "../types";
import { parseConcatenatedValueColumns } from "../shared/parsers";

export class SiteCategory extends ShiftCategory<string> {
  constructor() {
    super({
      id: SITE_ID,
      name: "Site / Location",
      detail:
        "Select column(s) that specify the site, location, and/or project where this volunteer shift will be working.",
      color: "#008080", // teal
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    return parseConcatenatedValueColumns(data, columns);
  }

  validateParsedData(parsedData: IParsedData<string>, columns: number[]) {
    const cellErrors: Array<ICellError> = [];
    let emptyRowIndex = -1;
    parsedData.values.forEach((value, rowIdx) => {
      if (value === "") {
        cellErrors.push({
          row: rowIdx,
          message: "Sites cannot be blank.",
          columns,
        });
        emptyRowIndex = rowIdx;
      }
    });
    if (cellErrors.length > 0) {
      return {
        isValid: false,
        errorMessage: `Site values must not be empty (Row ${emptyRowIndex +
          1})`,
        cellErrors,
      };
    }
    return {
      isValid: true,
    };
  }

  createSummary(parsedData: IParsedData<string>): ISummary {
    const uniqueValues = new Set<string>();
    const uniqueValueList: string[] = [];
    parsedData.values.forEach(value => {
      if (!uniqueValues.has(value)) {
        uniqueValueList.push(value);
        uniqueValues.add(value);
      }
    });
    let summary = "";
    if (uniqueValueList.length === 1) {
      summary = uniqueValueList[0];
    } else if (uniqueValueList.length > 1) {
      summary = `${uniqueValueList[0]}, ${uniqueValueList[1]}`;
      if (uniqueValueList.length > 2) {
        summary += `, and ${pluralize(
          "other",
          uniqueValueList.length - 2,
          true
        )}`;
      }
    }

    return {
      status: "INFO",
      short: pluralize("unique value", uniqueValues.size, true),
      full: {
        label: `${pluralize("unique value", uniqueValues.size, true)}:`,
        summary,
      },
    };
  }

  public getFollowUpQuestions(): Array<IFollowUpQuestion> {
    return [];
  }

  public mapRow(shift: IShift, parsedRow: string) {
    return {
      ...shift,
      site: parsedRow,
    };
  }
}
