import { Category } from "../category";
import { IVolunteer } from "../../scheduler/types";
import { CategoryIdType, VOLUNTEER_TYPE } from "../constants";
import { FollowUpAnswerMap } from "../types";

export abstract class VolunteerCategory<T> extends Category<T> {
  constructor(params: {
    id: CategoryIdType;
    name: string;
    detail: string;
    color: string;
  }) {
    super({
      ...params,
      type: VOLUNTEER_TYPE,
    });
  }

  public abstract mapRow(
    volunteer: IVolunteer,
    parsedRow: T,
    followUpAnswers: FollowUpAnswerMap
  ): IVolunteer;
}
