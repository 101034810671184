import React from "react";
import styled from "styled-components";
import { ITableData } from "../scheduler/store/types";
import {
  FollowUpAnswerMap,
  IFollowUpQuestion,
  ISummary,
  IValidationInfo,
} from "../categories/types";
import { Category } from "../categories/category";

const ColumnStatsWrapper = styled.div<{ isError?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.isError
      ? `
    color: white;
    background: #db2828;
  `
      : ""}
`;

const LabelText = styled.span``;

const StatsText = styled.span`
  white-space: pre;
`;

interface IColumnStatsProps {
  tableData: ITableData;
  columns: Array<number>;
  category: Category<any> | undefined;
  summary: ISummary | undefined;
  validationInfo: IValidationInfo | undefined;
  followUpQuestions: Array<IFollowUpQuestion>;
  followUpAnswers: FollowUpAnswerMap;
}

export const ColumnStats: React.FC<IColumnStatsProps> = (
  props: IColumnStatsProps
) => {
  if (
    props.columns.length === 0 ||
    !props.summary ||
    (props.validationInfo && props.validationInfo.hasUnansweredFollowUps)
  ) {
    return <div />;
  }

  const isError = props.validationInfo && !props.validationInfo.isValid;

  return (
    <ColumnStatsWrapper isError={isError}>
      <LabelText>{props.summary.full.label}</LabelText>
      <StatsText>{props.summary.full.summary}</StatsText>
    </ColumnStatsWrapper>
  );
};
