import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { AppRouter } from "./AppRouter";
import * as serviceWorker from "./serviceWorker";
import { SchedulerState } from "./scheduler/store/schedulerStateContainer";

// Provide scheduler state at root level so it persists across tab changes
ReactDOM.render(
  <SchedulerState.Provider>
    <AppRouter />
  </SchedulerState.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
