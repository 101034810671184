import { VolunteerCategory } from "./volunteerCategory";
import { VOLUNTEER_UNIQUE_ID } from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IVolunteer } from "../../scheduler/types";
import { IParsedData, ISummary, IFollowUpQuestion, ICellError } from "../types";
import { parseConcatenatedValueColumns } from "../shared/parsers";

export class UniqueIdCategory extends VolunteerCategory<string> {
  constructor() {
    super({
      id: VOLUNTEER_UNIQUE_ID,
      name: "Unique ID",
      detail:
        "Select column(s) that uniquely identify volunteers. Common examples are name and email.",
      color: "#008080",
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    return parseConcatenatedValueColumns(data, columns);
  }

  validateParsedData(parsedData: IParsedData<string>, columns: number[]) {
    const cellErrors: Array<ICellError> = [];
    let emptyRowIndex = -1;
    parsedData.values.forEach((value, rowIdx) => {
      if (value === "") {
        cellErrors.push({
          row: rowIdx,
          message: "Volunteer IDs cannot be blank.",
          columns,
        });
        emptyRowIndex = rowIdx;
      }
    });

    if (cellErrors.length > 0) {
      return {
        isValid: false,
        errorMessage: `Volunteer ID values must not be empty (Row ${emptyRowIndex +
          1})`,
        cellErrors,
      };
    }

    const seenValuesToRow: { [name: string]: number } = {};
    for (let i = 0; i < parsedData.values.length; i++) {
      const value = parsedData.values[i];
      if (seenValuesToRow[value] !== undefined) {
        return {
          isValid: false,
          errorMessage: `Duplicate value "${value}" found on rows ${seenValuesToRow[
            value
          ] + 1} and ${i + 1}.`,
          cellErrors: [
            {
              row: seenValuesToRow[value],
              message: `Duplicate value found on row ${i + 1}`,
              columns,
            },
            {
              row: i,
              message: `Duplicate value found on row ${seenValuesToRow[value] +
                1}`,
              columns,
            },
          ],
        };
      } else {
        seenValuesToRow[value] = i;
      }
    }

    return {
      isValid: true,
    };
  }

  createSummary(): ISummary {
    return {
      status: "INFO",
      short: "Unique volunteer IDs",
      full: {
        label: "Unique volunteer IDs",
        summary: "",
      },
    };
  }

  public getFollowUpQuestions(): Array<IFollowUpQuestion> {
    return [];
  }

  public mapRow(volunteer: IVolunteer) {
    return volunteer;
  }
}
