import React from "react";
import styled from "styled-components";
import { ColumnStats } from "./ColumnStats";
import {
  IFollowUpQuestion,
  FollowUpAnswerMap,
  ISummary,
  IValidationInfo,
} from "../categories/types";
import { ITableData } from "../scheduler/store/types";
import { Button } from "semantic-ui-react";
import { Category } from "../categories/category";

const QuestionContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;

  &&& {
    button {
      margin-right: 20px;
      max-width: 300px;
    }
  }
`;

interface IProps {
  selectedColumns: Array<number>;
  selectedCategory: Category<any> | undefined;
  summary: ISummary | undefined;
  validationInfo: IValidationInfo | undefined;
  followUpQuestions: Array<IFollowUpQuestion>;
  followUpAnswers: FollowUpAnswerMap;
  currentFollowUpIndex: number;
  tableData: ITableData;
  handleFollowUpAnswered(value: string): void;
}

export const FollowUpQuestionView: React.FC<IProps> = (props: IProps) => {
  const currentFollowUpQuestion =
    props.followUpQuestions[props.currentFollowUpIndex];
  const currentFollowUpAnswer = currentFollowUpQuestion
    ? props.followUpAnswers[currentFollowUpQuestion.id]
    : undefined;

  return (
    <section>
      {currentFollowUpQuestion && (
        <QuestionContainer>
          <p>{currentFollowUpQuestion.questionText}</p>
          <OptionsWrapper>
            {currentFollowUpQuestion.options.map(option => (
              <Button
                key={option.value}
                basic={currentFollowUpAnswer !== option.value}
                onClick={() => props.handleFollowUpAnswered(option.value)}
                primary
              >
                {option.label}
              </Button>
            ))}
          </OptionsWrapper>
        </QuestionContainer>
      )}
      <ColumnStats
        tableData={props.tableData}
        columns={props.selectedColumns}
        category={props.selectedCategory}
        summary={props.summary}
        validationInfo={props.validationInfo}
        followUpQuestions={props.followUpQuestions}
        followUpAnswers={props.followUpAnswers}
      />
    </section>
  );
};
