import { IDateTimeRange, INumberRange } from "./types";
import { parseDateTime, getParsingError } from "./dateTimeUtils";
import { IParsedData, ICellError } from "../types";
import { ITableData } from "../../scheduler/store/types";

/*
 * Responsible for transforming column mappings and raw data into data usable by the model.
 */

const rangeRegex = /^([0-9]+)\s*(?:(?:-|to)([0-9]+))?$/i;

export function parseConcatenatedValueColumns(
  data: ITableData,
  columns: number[]
): IParsedData<string> {
  const values: string[] = [];
  data.data.forEach(row => {
    let parts: Array<string> = [];
    columns.forEach(col => {
      const val = row[col].trim();
      if (val !== "") {
        parts.push(val);
      }
    });
    values.push(parts.join(" - "));
  });
  return { values };
}

export function parseDateTimeColumns(
  data: ITableData,
  columns: number[],
  opts: {
    includePriority?: boolean;
  } = {}
): IParsedData<Array<IDateTimeRange>> {
  const values: Array<Array<IDateTimeRange>> = [];
  const parsingErrors: Array<ICellError> = [];
  data.data.forEach((row, rowIdx) => {
    const rowValues: Array<IDateTimeRange> = [];
    columns.forEach(column => {
      let value = row[column].trim();
      let priority: number | undefined = undefined;
      if (opts.includePriority) {
        const parts = value.split("(");
        value = parts[0].trim();
        if (parts[1] && parts[1].indexOf(")") > 0) {
          priority = parseInt(parts[1].slice(0, parts[1].indexOf(")")));
        }
      }
      const dateTimeRange = parseDateTime(value, {
        column,
        priority,
      });
      if (dateTimeRange && !dateTimeRange.error) {
        rowValues.push(dateTimeRange);
      } else if (value) {
        // Only a parsing error if it wasn't blank
        parsingErrors.push({
          row: rowIdx,
          columns: [column],
          message: getParsingError(value),
        });
      }
    });
    values.push(rowValues);
  });
  return { values, parsingErrors };
}

export function parseListValueColumns(
  data: ITableData,
  columns: number[]
): IParsedData<Array<string>> {
  const values: string[][] = [];
  data.data.forEach(row => {
    let rowValues: string[] = [];
    columns.forEach(col => {
      const values = row[col]
        .trim()
        .split(",")
        .filter(v => v !== "");
      rowValues.push(...values);
    });
    values.push(rowValues);
  });
  return { values };
}

/*
 * If one column is given, it may contain a range or single number. If two are given, the
 * first must be the start of the range.
 */
export function parseRangeColumns(
  data: ITableData,
  columns: number[]
): IParsedData<INumberRange> {
  if (columns.length === 1) {
    return parseRangeColumnSingle(data, columns[0]);
  } else {
    return parseRangeColumnMulti(data, columns);
  }
}

function parseRangeColumnSingle(
  data: ITableData,
  column: number
): IParsedData<INumberRange> {
  const values: Array<INumberRange> = [];
  data.data.forEach(row => {
    const match = rangeRegex.exec(row[column].trim());
    if (match) {
      values.push({
        start: match[1] ? parseInt(match[1], 10) : undefined,
        end: match[2] ? parseInt(match[2], 10) : undefined,
      });
    } else {
      values.push({});
    }
  });
  return { values };
}

function parseRangeColumnMulti(
  data: ITableData,
  columns: number[]
): IParsedData<INumberRange> {
  const values: Array<INumberRange> = [];
  if (columns.length !== 2) {
    data.data.forEach(() => values.push({}));
    return { values };
  }

  data.data.forEach(row => {
    let value: INumberRange = {};
    const first = row[columns[0]].trim();
    const second = row[columns[1]].trim();
    try {
      value = {
        start: first ? parseInt(first, 10) : undefined,
        end: second ? parseInt(second, 10) : undefined,
      };
    } catch {
      /* Swallow exception */
    }
    values.push(value);
  });
  return { values };
}
