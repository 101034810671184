import { CategoryIdType } from "../../categories/constants";

export const SchedulerPage = {
  UPLOAD: 0,
  SHIFTS: 1,
  VOLUNTEERS: 2,
  SCHEDULE: 3,
};

export type AsyncNotLoaded = {
  loadState: "NOT_STARTED";
};

export type AsyncLoading = {
  loadState: "LOADING";
};

export type AsyncLoaded<T> = {
  loadState: "LOADED";
  value: T;
};

export type AsyncFailed = {
  loadState: "ERROR";
  error: Error;
};

export type AsyncData<T> =
  | AsyncNotLoaded
  | AsyncLoading
  | AsyncLoaded<T>
  | AsyncFailed;

export interface IColumnMapping {
  columns: Array<number>;
  category: CategoryIdType;
  color: string;
  followUpAnswers: { [questionId: string]: string };
}

export interface ITableData {
  headers: string[];
  data: string[][];
}

export interface ISchedulerState {
  page: number;
  maxPage: number;
  shiftData: AsyncData<ITableData>;
  shiftColumnMappings: Array<IColumnMapping>;
  volunteerColumnMappings: Array<IColumnMapping>;
  volunteerData: AsyncData<ITableData>;
}
