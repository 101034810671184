import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { SchedulerState } from "./store/schedulerStateContainer";
import { Upload } from "./Upload";
import { SchedulerPage } from "./store/types";
import { SchedulerSteps } from "./SchedulerSteps";
import { ColumnConfiguration } from "../column-configuration/ColumnConfiguration";
import { ScheduleResults } from "./ScheduleResults";
import {
  SITE_ID,
  SHIFT_DATETIME_ID,
  VOLUNTEER_UNIQUE_ID,
  AVAILABILITIES_ID,
  NUM_VOLUNTEERS_ID,
  ROLES_ID,
  PRIORITY_ID,
  REQUIRED_ROLES_ID,
  PREFERRED_SITES_CATEGORY_ID,
  GROUP_CATEGORY_ID,
} from "../categories/constants";

const FullHeightDiv = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background-color: white;
`;

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const SchedulerContent: React.FC = () => {
  const {
    page,
    shiftData,
    volunteerData,
    shiftColumnMappings,
    setShiftColumnMappings,
    volunteerColumnMappings,
    setVolunteerColumnMappings,
  } = SchedulerState.useContainer();

  switch (page) {
    case SchedulerPage.UPLOAD:
      return <Upload />;
    case SchedulerPage.SHIFTS:
      return (
        <ColumnConfiguration
          key="shift-configuration"
          tableData={shiftData}
          columnMappings={shiftColumnMappings}
          setColumnMappings={setShiftColumnMappings}
          categoryIds={[
            SITE_ID,
            SHIFT_DATETIME_ID,
            NUM_VOLUNTEERS_ID,
            REQUIRED_ROLES_ID,
          ]}
          requiredCategoryIds={[SITE_ID, SHIFT_DATETIME_ID]}
          title="Shift configuration"
        />
      );
    case SchedulerPage.VOLUNTEERS:
      return (
        <ColumnConfiguration
          key="volunteer-configuration"
          tableData={volunteerData}
          columnMappings={volunteerColumnMappings}
          setColumnMappings={setVolunteerColumnMappings}
          categoryIds={[
            VOLUNTEER_UNIQUE_ID,
            AVAILABILITIES_ID,
            PRIORITY_ID,
            ROLES_ID,
            PREFERRED_SITES_CATEGORY_ID,
            GROUP_CATEGORY_ID,
          ]}
          requiredCategoryIds={[VOLUNTEER_UNIQUE_ID, AVAILABILITIES_ID]}
          title="Volunteer configuration"
        />
      );
    case SchedulerPage.SCHEDULE:
      return <ScheduleResults />;
    default:
      return null;
  }
};

export const Scheduler: React.FC = () => {
  return (
    <FullHeightDiv>
      <SchedulerSteps />
      <ContentWrapper>
        <SchedulerContent />
      </ContentWrapper>
      <Helmet>
        <title>VolSchedule | Scheduler</title>
        <meta
          name="description"
          content="Upload schedules, configure constraints, and generate optimized volunteer schedules."
        />
      </Helmet>
    </FullHeightDiv>
  );
};
