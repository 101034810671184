import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import styled from "styled-components";
import { Routes } from "./constants";
import { Footer } from "./Footer";

const WrappingDiv = styled.div`
  background-color: white;
`;

const WelcomeSegment = styled(Segment)`
  min-height: 700px;
`;

const InfoSegment = styled(Segment)`
  padding: 80px 0;
  min-height: 500px;
`;

const MastheadContainer = styled(Container)`
  margin-top: 200px;
`;

const Masthead = styled.h1`
  font-size: 64px;
  color: white;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const MoreButton = styled(Button)`
  &&& {
    margin-left: 40px;
    background-color: white;
  }
`;

const DetailsContainer = styled(Container)`
  margin-top: 40px;
`;

export const Home: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}>
) => {
  return (
    <WrappingDiv>
      <WelcomeSegment textAlign="center" inverted vertical>
        <MastheadContainer>
          <Masthead>VolSchedule</Masthead>
          <Header as="h2" inverted>
            Create optimized volunteer schedules in the blink of an eye
          </Header>
          <ButtonContainer>
            <Button
              primary
              onClick={() => props.history.replace(Routes.SCHEDULER)}
            >
              Get Started
              <Icon name="arrow right" />
            </Button>
            <MoreButton onClick={() => props.history.replace(Routes.GUIDES)}>
              Learn more
            </MoreButton>
          </ButtonContainer>
        </MastheadContainer>
      </WelcomeSegment>
      <InfoSegment vertical>
        <DetailsContainer text textAlign="left">
          <h2>Designed with Volunteer Managers in mind.</h2>
          <p>
            VolSchedule is made for volunteer managers with dozens or hundreds
            of volunteers to assign to a schedule, each with their own set of
            availabilities and preferences. It is best suited for programs where
            many volunteers are scheduled at once for weekly shifts.
            <br />
            <br />
            I was motivated to create this tool after working with a team of
            volunteer managers to assign several hundred tutors to weekly shifts
            in in classrooms across seventeen different schools. Each volunteer
            indicated times they were available, and some also requested to be
            placed in a particular classroom. Each assigned group of tutors alse
            needed to have a driver to get the group to and from the weekly
            shift. Matching volunteers to shifts manually was a long and
            exhasting process, and inevitably resulted in a handful of
            volunteers left unscheduled, teachers whose requests for volunteers
            were left unmet, or both. There must be a better way.
            <br />
            <br />
            VolSchedule aims to make problems like this quick and as painless as
            possible by letting computers do what they're best at, and leaving
            more time for volunteer managers to do what they're best at.
          </p>
          <br />
          <Divider />
          <h2>Easy to use.</h2>
          <p>
            First, you'll upload spreadsheets with your vounteer data and open
            shifts. Next, we'll ask you to tell us a bit about what's in each
            column. Then, we'll use the constraints you've given to generate a
            schedule that assigns as many volunteers and meets as many of their
            preferences as possible.
          </p>
          <br />
          <Divider />
          <h2>Secure.</h2>
          <p>
            We believe the best way to protect your data is to not collect it in
            the first place. Once we've generated your schedule, we don't store
            any volunteer data on our servers.
          </p>
          <br />
          <Divider />
          <Button
            primary
            basic
            onClick={() => props.history.replace(Routes.GUIDES)}
          >
            Learn more
          </Button>
        </DetailsContainer>
        {/* <Container>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <h2>Designed for Volunteer Managers</h2>
                <p>
                  VolSchedule is tailor-made for volunteer managers with dozens
                  or hundreds of volunteers to schedule, each with their own set
                  of availabilities and preferences.
                </p>
              </Grid.Column>
              <Grid.Column width={8}>
                <img
                  alt="Table configuration with highlighted columns"
                  src={ShiftConfigHomeImg}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container>
          <Grid verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Icon size="massive" name="lock" />
              </Grid.Column>
              <Grid.Column width={8}>
                <h2>Secure</h2>
                <p>
                  We don't store your volunteer data, ever. Once we've generated
                  your schedule, we don't hold on to anything on our servers.
                  All network requests are SSL-encrypted per industry standards
                  to prevent man-in-the-middle attacks.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container> */}
      </InfoSegment>
      <Footer />
    </WrappingDiv>
  );
};
