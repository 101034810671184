export const SITE_ID = "SITE";
export const SHIFT_DATETIME_ID = "SHIFT_DATETIME";
export const NUM_VOLUNTEERS_ID = "NUM_VOLUNTEERS";
export const REQUIRED_ROLES_ID = "REQUIRED_ROLES";
export const VOLUNTEER_UNIQUE_ID = "UNIQUE_ID";
export const AVAILABILITIES_ID = "AVAILABILITIES";
export const ROLES_ID = "ROLES";
export const PRIORITY_ID = "PROIRITY";
export const PREFERRED_SITES_CATEGORY_ID = "PREFERRED_SITES";
export const GROUP_CATEGORY_ID = "GROUP";

export type CategoryIdType =
  | typeof SITE_ID
  | typeof SHIFT_DATETIME_ID
  | typeof NUM_VOLUNTEERS_ID
  | typeof REQUIRED_ROLES_ID
  | typeof VOLUNTEER_UNIQUE_ID
  | typeof AVAILABILITIES_ID
  | typeof ROLES_ID
  | typeof PRIORITY_ID
  | typeof PREFERRED_SITES_CATEGORY_ID
  | typeof GROUP_CATEGORY_ID;

export const SHIFT_TYPE = "shift";
export const VOLUNTEER_TYPE = "volunteer";

export const DATE_TIME_JOIN_TYPE_ID = "DATE_TIME_JOIN_TYPE";
export const JOIN_AND = "AND";
export const JOIN_OR = "OR";

export const NUM_VOLUNTEERS_TYPE_ID = "NUM_VOLUNTEERS_TYPE";
export const MIN = "MIN";
export const MAX = "MAX";

export const REQUIRED_ROLES_NUM_TYPE_ID = "REQUIRED_ROLES_NUM_TYPE";
export const ONE_REQUIRED = "ONE_REQUIRED";
export const ALL_REQUIRED = "ALL_REQUIRED";

export const NUM_MINUTES_IN_DAY = 1440;
