import pluralize from "pluralize";
import isNaN from "lodash.isnan";
import { VolunteerCategory } from "./volunteerCategory";
import { PRIORITY_ID } from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IVolunteer } from "../../scheduler/types";
import { IParsedData, ISummary, IFollowUpQuestion, ICellError } from "../types";

type Priority = 1 | 2 | 3;

export class PriorityCategory extends VolunteerCategory<Priority> {
  constructor() {
    super({
      id: PRIORITY_ID,
      name: "Priority",
      detail:
        "Select a column for volunteer priority: 1 = high priority, 2 = medium priority, 3 = low priority.",
      color: "#EE82EE", // violet
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    const values: Array<Priority> = [];
    const parsingErrors: Array<ICellError> = [];
    data.data.forEach((row: string[], rowIdx: number) => {
      let errorMessage = "";
      if (columns.length !== 1) {
        errorMessage =
          columns.length === 0
            ? "A column must be selected"
            : "Only one priority column may be selected.";
      } else if (!row[columns[0]]) {
        errorMessage = "Priority values must not be empty.";
      } else {
        try {
          const priority = parseInt(row[columns[0]], 10);
          if (priority === 1 || priority === 2 || priority === 3) {
            values.push(priority);
          } else if (isNaN(priority)) {
            errorMessage = `Could not parse "${
              row[columns[0]]
            }". Must be 1, 2, or 3.`;
          } else {
            errorMessage = "Priority must be 1, 2, or 3.";
          }
        } catch {
          errorMessage = `Could not parse "${
            row[columns[0]]
          }". Must be 1, 2, or 3.`;
        }
      }

      if (errorMessage) {
        values.push(3);
        parsingErrors.push({
          row: rowIdx,
          columns,
          message: errorMessage,
        });
      }
    });

    return { values, parsingErrors };
  }

  validateParsedData() {
    return { isValid: true };
  }

  createSummary(parsedData: IParsedData<Priority>): ISummary {
    const counts = {
      1: 0,
      2: 0,
      3: 0,
    };
    parsedData.values.forEach(priority => {
      counts[priority] = counts[priority] + 1;
    });

    const short = `High (${counts[1]}), Medium (${counts[2]}), Low (${counts[3]})`;
    const summary =
      `${pluralize("high priority volunteer", counts[1], true)}, ` +
      `${pluralize("medium priority volunteer", counts[2], true)}, and ` +
      `${pluralize("low priority volunteer", counts[3], true)}.`;

    return {
      status: "INFO",
      short,
      full: {
        label: summary,
        summary: "",
      },
    };
  }

  public getFollowUpQuestions(): Array<IFollowUpQuestion> {
    return [];
  }

  public mapRow(volunteer: IVolunteer, priority: Priority): IVolunteer {
    return {
      ...volunteer,
      priority,
    };
  }
}
