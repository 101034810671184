import React, { useState } from "react";
import { Table, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ICellError } from "../categories/types";
import { ITableHeaderCell, ITableCell } from "./types";
import { SchedulerTableCell } from "./SchedulerTableCell";

const TableArea = styled.div<{ height: number }>`
  margin-top: 20px;
  height: ${props => props.height}px;
  width: 100%;
  background: #ededed;
  overflow: auto;
  border: 1px solid gray;
  border-radius: 4px;

  p {
    text-align: center;
    padding-top: 180px;
    color: gray;
  }
`;

const WarningIcon = styled(Icon)`
  && {
    margin-left: 10px;
  }
`;

export interface ISchedulerTableProps {
  /* Data and metadata for header cell display */
  headers: ITableHeaderCell[];

  /* Date and metadata for body cell display */
  data: ITableCell[][];

  /* Given in px. Default is 400 */
  height?: number;

  /* Cell-level errors */
  cellErrors?: Array<ICellError>;

  /* When present, columns are selectable. */
  onColumnClick?: (column: number) => void;
}

export const SchedulerTable: React.FC<ISchedulerTableProps> = (
  props: ISchedulerTableProps
) => {
  const [hoverColumn, setHoverColumn] = useState<number | undefined>(undefined);
  const height = props.height || 400;

  function handleMouseOut() {
    if (props.onColumnClick) {
      setHoverColumn(undefined);
    }
  }

  function handleCellClick(column: number) {
    if (props.onColumnClick) {
      props.onColumnClick(column);
    }
  }

  return (
    <TableArea height={height}>
      <Table onMouseOut={handleMouseOut} striped>
        <Table.Header>
          <Table.Row>
            <SchedulerTableCell key={`empty-header`} index={-1} isHeader />
            {props.headers.map((cell, idx) => (
              <SchedulerTableCell
                key={`header-${idx}`}
                onMouseOver={props.onColumnClick ? setHoverColumn : undefined}
                highlight={hoverColumn === idx}
                index={idx}
                onClick={props.onColumnClick ? handleCellClick : undefined}
                color={cell.color}
                popupText={cell.popupText}
                isHeader
              >
                {cell.text}
                {cell.hasError && <WarningIcon name="warning sign" />}
              </SchedulerTableCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.data.map((row, rowIdx) => (
            <Table.Row key={`row-${rowIdx + 1}`}>
              <SchedulerTableCell key={`row-${rowIdx + 1}-number`} index={-1}>
                {rowIdx + 1}
              </SchedulerTableCell>
              {row.map((cell, cellIdx) => (
                <SchedulerTableCell
                  key={`cell-${rowIdx + 1}_${cellIdx}`}
                  onMouseOver={props.onColumnClick ? setHoverColumn : undefined}
                  highlight={hoverColumn === cellIdx}
                  index={cellIdx}
                  onClick={props.onColumnClick ? handleCellClick : undefined}
                  color={cell.color}
                  popupText={cell.popupText}
                >
                  {cell.text}
                </SchedulerTableCell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </TableArea>
  );
};
