import { ShiftCategory } from "./shiftCategory";
import {
  SHIFT_DATETIME_ID,
  DATE_TIME_JOIN_TYPE_ID,
  JOIN_AND,
  JOIN_OR,
  NUM_MINUTES_IN_DAY,
} from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IShift, IShiftTime } from "../../scheduler/types";
import {
  IParsedData,
  ISummary,
  IFollowUpQuestion,
  FollowUpAnswerMap,
} from "../types";
import { parseDateTimeColumns } from "../shared/parsers";
import { IDateTimeRange } from "../shared/types";
import { formatDateAndTimeRange, formatDate } from "../shared/dateTimeUtils";
import { validateDateTimeValues } from "../shared/validators";

function dateTimeRangeToShiftTime(
  dateTimeRange: IDateTimeRange,
  id: number
): IShiftTime {
  let dateStr = dateTimeRange.dayOfWeek;
  if (!dateStr) {
    if (dateTimeRange.date) {
      dateStr = formatDate(dateTimeRange.date);
    } else {
      dateStr = "";
    }
  }

  return {
    id,
    date_str: dateStr,
    start_time: dateTimeRange.range ? dateTimeRange.range.startMinuteOfDay : 0,
    end_time: dateTimeRange.range
      ? dateTimeRange.range.endMinuteOfDay
      : NUM_MINUTES_IN_DAY,
  };
}

export class ShiftDateTimeCategory extends ShiftCategory<
  Array<IDateTimeRange>
> {
  constructor() {
    super({
      id: SHIFT_DATETIME_ID,
      name: "Date / Time",
      detail:
        "Select column(s) that specify the date, day of week, and/or time of each shift.",
      color: "#A52A2A", // brown
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    return parseDateTimeColumns(data, columns);
  }

  validateParsedData(
    parsedData: IParsedData<Array<IDateTimeRange>>,
    columns: number[]
  ) {
    return validateDateTimeValues(parsedData, columns);
  }

  createSummary(parsedData: IParsedData<Array<IDateTimeRange>>): ISummary {
    let short = "";
    let label = "";
    const firstValue =
      parsedData.values[0].length > 0 && parsedData.values[0][0];
    if (firstValue && firstValue.range) {
      if (firstValue.dayOfWeek) {
        label = "Match volunteers based on day of week and time ranges:";
        short = "Match on time and day of week";
      } else if (firstValue.date) {
        label = "Match volunteers based on date and time ranges:";
        short = "Match on time and date";
      } else {
        label = "Match volunteers based on time ranges only:";
        short = "Match on time ranges";
      }
    } else {
      if (firstValue && firstValue.dayOfWeek) {
        label =
          "No time ranges found. Only the day of week will be used to match volunteers to shifts:";
        short = "Match on day of week";
      } else if (firstValue && firstValue.dayOfWeek) {
        label =
          "No time ranges found. Only the date will be used to match volunteers to shifts:";
        short = "Match on date";
      }
    }

    return {
      status: "INFO",
      short,
      full: {
        label,
        summary:
          parsedData.values[0].length > 0
            ? `Example: "${formatDateAndTimeRange(parsedData.values[0][0])}"`
            : "",
      },
    };
  }

  public getFollowUpQuestions(
    parsedData: IParsedData<Array<IDateTimeRange>>,
    columns: number[]
  ): Array<IFollowUpQuestion> {
    if (columns.length > 1) {
      return [
        {
          id: DATE_TIME_JOIN_TYPE_ID,
          questionText:
            "How should the dates and times in different columns be combined?",
          options: [
            {
              label: "Volunteers should be available for ALL times listed.",
              value: JOIN_AND,
            },
            {
              label:
                "Only one of the listed times should be chosen as the assigned shift time.",
              value: JOIN_OR,
            },
          ],
        },
      ];
    }
    return [];
  }

  public mapRow(
    shift: IShift,
    parsedRow: Array<IDateTimeRange>,
    followUpAnswers: FollowUpAnswerMap
  ) {
    if (followUpAnswers[DATE_TIME_JOIN_TYPE_ID] === JOIN_OR) {
      return parsedRow.map((dateTime, idx) => {
        // This should probably be a deep copy.. but it might not matter.
        return {
          ...shift,
          shift_times: [dateTimeRangeToShiftTime(dateTime, 0)],
          option_number: idx,
        };
      });
    } else {
      return {
        ...shift,
        shift_times: parsedRow.map(dateTimeRangeToShiftTime),
      };
    }
  }
}
