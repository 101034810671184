import React from "react";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";

const StyledFooter = styled.div`
  padding: 20px;
  &&& {
    display: flex;
    justify-content: space-between;
    a {
      color: white;
    }
  }
`;

export const Footer: React.FC<{}> = () => {
  return (
    <Segment inverted vertical>
      <StyledFooter>
        <span>VolSchedule 2020.</span>
        <span>
          Contact:{" "}
          <a href="mailto:info@volschedule.com">info@volschedule.com</a>
        </span>
      </StyledFooter>
    </Segment>
  );
};
