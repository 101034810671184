import { IColumnMapping, ITableData, AsyncData } from "./store/types";
import { IShift, IVolunteer, DerivedDataMap } from "./types";
import flatMap from "lodash.flatmap";
import { getCategory } from "../categories/categoryMap";
import { isShiftCategory } from "../categories/shifts/util";
import { isVolunteerCategory } from "../categories/volunteers/util";

export function generateShifts(
  columnMappings: Array<IColumnMapping>,
  table: AsyncData<ITableData>,
  derivedData: DerivedDataMap
): Array<IShift> {
  if (table.loadState !== "LOADED") {
    return [];
  }

  return flatMap(table.value.data, (row, idx) => {
    let shifts: Array<IShift> = [
      {
        id: idx,
      },
    ];

    columnMappings.forEach(mapping => {
      const category = getCategory(mapping.category);
      if (isShiftCategory(category)) {
        shifts = flatMap(shifts, (shift: IShift) => {
          if (!derivedData[mapping.category]) {
            console.warn(
              `Could not find parsed data for category ${mapping.category}.`
            );
            return shift;
          }
          return category.mapRow(
            shift,
            derivedData[mapping.category].parsedData.values[idx],
            mapping.followUpAnswers
          );
        });
      }
    });

    return shifts;
  });
}

export function generateVolunteers(
  columnMappings: Array<IColumnMapping>,
  table: AsyncData<ITableData>,
  derivedData: DerivedDataMap
): Array<IVolunteer> {
  if (table.loadState !== "LOADED") {
    return [];
  }

  return table.value.data.map((row, idx) => {
    let volunteer: IVolunteer = {
      id: idx,
    };

    columnMappings.forEach(mapping => {
      const category = getCategory(mapping.category);
      if (isVolunteerCategory(category)) {
        if (!derivedData[mapping.category]) {
          console.warn(
            `Could not find parsed data for category ${mapping.category}.`
          );
        } else {
          volunteer = category.mapRow(
            volunteer,
            derivedData[mapping.category].parsedData.values[idx],
            mapping.followUpAnswers
          );
        }
      }
    });

    return volunteer;
  });
}
