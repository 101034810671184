import pluralize from "pluralize";
import { VolunteerCategory } from "./volunteerCategory";
import { ROLES_ID } from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IVolunteer } from "../../scheduler/types";
import { IParsedData, ISummary, IFollowUpQuestion } from "../types";

export class RolesCategory extends VolunteerCategory<Array<string>> {
  constructor() {
    super({
      id: ROLES_ID,
      name: "Roles",
      detail: "Select column(s) that specify roles that each volunteer has.",
      color: "#016936", // green
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    return {
      values: data.data.map((row: string[]) => {
        const roles: string[] = [];
        columns.forEach(col => {
          roles.push(
            ...row[col]
              .split(",")
              .map(val => val.trim().toLowerCase())
              .filter(val => val !== "")
          );
        });
        return roles;
      }),
    };
  }

  validateParsedData() {
    return { isValid: true };
  }

  createSummary(parsedData: IParsedData<Array<string>>): ISummary {
    const roleCounts: { [role: string]: number } = {};
    parsedData.values.forEach(roles => {
      roles.forEach(role => {
        if (roleCounts[role]) {
          roleCounts[role] = roleCounts[role] + 1;
        } else {
          roleCounts[role] = 1;
        }
      });
    });

    const uniqueRoles = Object.keys(roleCounts);
    uniqueRoles.sort((a, b) => roleCounts[b] - roleCounts[a]);
    let summary = "";
    if (uniqueRoles.length === 1) {
      summary = uniqueRoles[0];
    } else if (uniqueRoles.length === 2) {
      summary = `${uniqueRoles[0]} (${roleCounts[uniqueRoles[0]]}) and ${
        uniqueRoles[1]
      } (${roleCounts[uniqueRoles[1]]})`;
    } else if (uniqueRoles.length > 2) {
      summary =
        `${uniqueRoles[0]} (${roleCounts[uniqueRoles[0]]}), ${
          uniqueRoles[1]
        } (${roleCounts[uniqueRoles[1]]}),` +
        ` + ${uniqueRoles.length - 2} more`;
    }

    return {
      status: "INFO",
      short: pluralize("role", uniqueRoles.length, true),
      full: {
        label: pluralize("role", uniqueRoles.length, true),
        summary,
      },
    };
  }

  public getFollowUpQuestions(): Array<IFollowUpQuestion> {
    return [];
  }

  public mapRow(volunteer: IVolunteer, roles: Array<string>): IVolunteer {
    return {
      ...volunteer,
      roles,
    };
  }
}
