import { IParsedData, IValidationInfo, ICellError } from "../types";
import { IDateTimeRange } from "./types";

export function validateDateTimeValues(
  parsedData: IParsedData<Array<IDateTimeRange>>,
  columns: number[],
  opts: {
    allowEmptyRow?: boolean;
  } = {}
): IValidationInfo {
  // Validate that rows are not empty
  const emptyRows: number[] = [];
  parsedData.values.forEach((times, idx) => {
    if (times.length === 0) {
      emptyRows.push(idx);
    }
  });
  if (emptyRows.length > 0 && !opts.allowEmptyRow) {
    return {
      isValid: false,
      errorMessage: "All rows must have a date/time value.",
      cellErrors: emptyRows.map(row => ({
        row,
        columns,
        message: "At least one column must have a date/time value.",
      })),
    };
  }

  // Validate that all rows have the same type of information.
  const hasDate =
    parsedData.values[0].length > 0 && !!parsedData.values[0][0].date;
  const hasRange =
    parsedData.values[0].length > 0 && !!parsedData.values[0][0].range;
  const hasDayOfWeek =
    parsedData.values[0].length > 0 && !!parsedData.values[0][0].dayOfWeek;
  const cellErrors: Array<ICellError> = [];
  parsedData.values.forEach((values, rowIdx) => {
    values.forEach(value => {
      let message: string | undefined;
      if (hasDate && !value.date) {
        message =
          "Date/time in row 1 has a date value, but no date found in this cell.";
      } else if (!hasDate && !!value.date) {
        message =
          "Date/time in row 1 has no date value, but a date was found in this cell.";
      } else if (hasRange && !value.range) {
        message =
          "Date/time in row 1 has a time range, but no time range found in this cell.";
      } else if (!hasRange && !!value.range) {
        message =
          "Date/time in row 1 has no time range, but a time range was found in this cell.";
      } else if (hasDayOfWeek && !value.dayOfWeek) {
        message =
          "Date/time in row 1 has a day of the week, but no day of the week found in this cell.";
      } else if (!hasDayOfWeek && !!value.dayOfWeek) {
        message =
          "Date/time in row 1 has no day of the week, but a day of the week was found in this cell.";
      }
      if (message) {
        cellErrors.push({
          row: rowIdx,
          columns: value.column !== undefined ? [value.column] : columns,
          message,
        });
      }
    });
  });
  if (cellErrors.length > 0) {
    return {
      isValid: false,
      errorMessage: "Not all date/time values are formatted the same way.",
      cellErrors,
    };
  }

  return {
    isValid: true,
  };
}
