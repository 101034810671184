import React from "react";
import pluralize from "pluralize";
import styled from "styled-components";
import { Container } from "semantic-ui-react";
import { ColumnCategories } from "./ColumnCategories";
import { IColumnMapping, ITableData } from "../scheduler/store/types";
import {
  IFollowUpQuestion,
  FollowUpAnswerMap,
  ISummary,
  IValidationInfo,
} from "../categories/types";
import { SelectedColumns } from "./SelectedColumns";
import { ColumnStats } from "./ColumnStats";
import { Category } from "../categories/category";
import { CategoryIdType } from "../categories/constants";

const Wrapper = styled.div``;

const InstructionText = styled.div`
  text-align: center;
  width: 100%;
`;

function getHeaderText(
  selectedCategory: Category<any> | undefined,
  selectedColumns: Array<number>,
  numFollowUps: number
): string {
  if (selectedCategory && selectedColumns.length > 0) {
    return `Select any additional columns that describe the ${selectedCategory.getName()}, or click "${
      numFollowUps > 0 ? "Next" : "Confirm"
    }" below.`;
  } else if (selectedCategory) {
    return selectedCategory.getDetail();
  } else if (selectedColumns.length > 0) {
    return `${pluralize("column", selectedColumns.length, true)} selected:`;
  } else {
    return (
      "To add schedule constraints, please select a column above or a category below. " +
      'If you are finished, please click "Finish" below.'
    );
  }
}

interface IProps {
  tableData: ITableData;
  selectedCategory: Category<any> | undefined;
  selectedColumns: Array<number>;
  summary: ISummary | undefined;
  validationInfo: IValidationInfo | undefined;
  followUpQuestions: Array<IFollowUpQuestion>;
  followUpAnswers: FollowUpAnswerMap;
  columnMappings: Array<IColumnMapping>;
  categoryIds: Array<CategoryIdType>;
  requiredCategoryIds: Array<CategoryIdType>;
  selectCategory(categoryId: string): void;
  toggleColumnSelection(column: number): void;
}

export const CategoryColumnView: React.FC<IProps> = (props: IProps) => {
  const hasCategory = props.selectedCategory !== undefined;
  const hasColumns = props.selectedColumns.length > 0;

  return (
    <Wrapper>
      <ColumnCategories
        categoryIds={props.categoryIds}
        requiredCategories={props.requiredCategoryIds}
        columnMappings={props.columnMappings}
        selectedCategoryId={
          props.selectedCategory && props.selectedCategory.getId()
        }
        onSelectCategoryId={props.selectCategory}
      />
      <InstructionText>
        {getHeaderText(
          props.selectedCategory,
          props.selectedColumns,
          props.followUpQuestions.length
        )}
      </InstructionText>
      {hasColumns && (
        <SelectedColumns
          columns={props.selectedColumns}
          columnNames={props.tableData.headers}
          onRemoveColumn={props.toggleColumnSelection}
        />
      )}
      <div>
        {hasColumns && !hasCategory && (
          <Container text textAlign="center">
            Please select a category for{" "}
            {props.selectedColumns.length > 1
              ? "these columns above."
              : "this column above."}
          </Container>
        )}
      </div>
      {hasCategory && hasColumns && (
        <ColumnStats
          tableData={props.tableData}
          columns={props.selectedColumns}
          category={props.selectedCategory}
          summary={props.summary}
          validationInfo={props.validationInfo}
          followUpQuestions={props.followUpQuestions}
          followUpAnswers={props.followUpAnswers}
        />
      )}
    </Wrapper>
  );
};
