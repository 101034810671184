import { ITableData } from "../scheduler/store/types";
import {
  IParsedData,
  IValidationInfo,
  ISummary,
  FollowUpAnswerMap,
  IFollowUpQuestion,
} from "./types";
import { CategoryIdType } from "./constants";

export abstract class Category<T> {
  private id: CategoryIdType;
  private type: string;
  private name: string;
  private detail: string;
  private color: string;

  constructor({
    id,
    type,
    name,
    detail,
    color,
  }: {
    id: CategoryIdType;
    type: string;
    name: string;
    detail: string;
    color: string;
  }) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.detail = detail;
    this.color = color;
  }

  public abstract parseData(
    data: ITableData,
    columns: number[],
    followUpAnswers: FollowUpAnswerMap
  ): IParsedData<T>;
  abstract validateParsedData(
    parsedData: IParsedData<T>,
    columns: number[]
  ): IValidationInfo;
  abstract createSummary(
    parsedData: IParsedData<T>,
    columns: number[],
    followUpAnswers: FollowUpAnswerMap
  ): ISummary;
  public abstract getFollowUpQuestions(
    parsedData: IParsedData<T>,
    columns: number[]
  ): Array<IFollowUpQuestion>;

  public validate(
    data: ITableData,
    parsedData: IParsedData<T>,
    columns: number[],
    followUpQuestions: Array<IFollowUpQuestion>,
    followUpAnswers: FollowUpAnswerMap
  ): IValidationInfo {
    if (
      followUpQuestions.some(
        question => followUpAnswers[question.id] === undefined
      )
    ) {
      return {
        isValid: false,
        errorMessage:
          "This category has one or more unanswered follow-up questions.",
        hasUnansweredFollowUps: true,
      };
    }

    if (data.data.length !== parsedData.values.length) {
      return {
        isValid: false,
        errorMessage:
          "The length of the parsed values list does not match the number of rows.",
      };
    }

    if (parsedData.parsingErrors && parsedData.parsingErrors.length > 0) {
      return {
        isValid: false,
        errorMessage:
          parsedData.parsingErrors[0].message ||
          "Failed to parse values from some rows.",
        cellErrors: parsedData.parsingErrors,
      };
    }

    return this.validateParsedData(parsedData, columns);
  }

  public getSummary(
    parsedData: IParsedData<T>,
    columns: number[],
    validation: IValidationInfo,
    followUpAnswers: FollowUpAnswerMap
  ): ISummary {
    if (!validation.isValid) {
      return {
        status: "DANGER",
        short: validation.errorMessage || "",
        full: {
          label: "Error parsing column values:",
          summary: validation.errorMessage,
        },
      };
    }

    return this.createSummary(parsedData, columns, followUpAnswers);
  }

  public getId() {
    return this.id;
  }

  public getName() {
    return this.name;
  }

  public getDetail() {
    return this.detail;
  }

  public getColor() {
    return this.color;
  }

  public getType() {
    return this.type;
  }
}
