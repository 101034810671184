import React from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import { AsyncData, ITableData } from "./store/types";

const TableArea = styled.div<{ danger: boolean; height: number }>`
  margin-top: 20px;
  height: ${props => props.height}px;
  width: 100%;
  background: ${props => (props.danger ? "#ffcccc" : "#ededed")};
  overflow: auto;
  border: 1px solid gray;
  border-radius: 4px;
  &&& {
    ${props => (props.onClick ? "cursor: pointer;" : "")}
  }

  p {
    text-align: center;
    padding-top: 180px;
    color: gray;
  }
`;

const AlertText = styled.p`
  color: red;
`;

const StickyHeaderCell = styled(Table.HeaderCell)`
  position: sticky;
  top: 0;
`;

export interface ILoadableTableProps {
  asyncData: AsyncData<ITableData>;

  /* Given in px. Default is 400 */
  height?: number;

  /* Defaults to empty string */
  placeholder?: string;

  /* onClick callback for table area */
  onClick?: () => void;
}

// TODO: This whole component should be rewritten. Tables are organized into rows, then columns;
//       we really need to do columns first to allow styles to cascade down.
export const LoadableTable: React.FC<ILoadableTableProps> = (
  props: ILoadableTableProps
) => {
  const placeholder = props.placeholder || "";
  const height = props.height || 400;

  let tableEl = <p>{placeholder}</p>;

  // This should probably have a loading state, but will likely be <100ms
  if (props.asyncData.loadState === "LOADED") {
    tableEl = (
      <Table striped>
        <Table.Header>
          <Table.Row>
            {props.asyncData.value.headers.map((cell, idx) => (
              <StickyHeaderCell key={`header-${idx}`}>{cell}</StickyHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.asyncData.value.data.map((row, rowIdx) => (
            <Table.Row key={`row-${rowIdx + 1}`}>
              {row.map((cell, cellIdx) => (
                <Table.Cell key={`cell-${rowIdx + 1}_${cellIdx}`}>
                  {cell}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  } else if (props.asyncData.loadState === "ERROR") {
    tableEl = <AlertText>Failed to load CSV file.</AlertText>;
  }

  return (
    <TableArea
      danger={props.asyncData.loadState === "ERROR"}
      height={height}
      onClick={props.onClick}
    >
      {tableEl}
    </TableArea>
  );
};
