import flatMap from "lodash.flatmap";
import pluralize from "pluralize";
import { ShiftCategory } from "./shiftCategory";
import {
  REQUIRED_ROLES_ID,
  REQUIRED_ROLES_NUM_TYPE_ID,
  ONE_REQUIRED,
  ALL_REQUIRED,
} from "../constants";
import { ITableData } from "../../scheduler/store/types";
import { IShift, IShiftRole } from "../../scheduler/types";
import {
  IParsedData,
  ISummary,
  IFollowUpQuestion,
  FollowUpAnswerMap,
} from "../types";

const numNeededRegex = /^(.*)\s+\(([0-9]+)\)$/;

export class RequiredRolesCategory extends ShiftCategory<Array<IShiftRole>> {
  constructor() {
    super({
      id: REQUIRED_ROLES_ID,
      name: "Required roles",
      detail:
        "Select column(s) that specify special roles required by some or all volunteers in a shift. If only some" +
        ' volunteers require that role, indicate the number required in parenthesis. For example: "Driver (2)"',
      color: "#EE82EE", // violet
    });
  }

  public parseData(data: ITableData, columns: number[]) {
    const roles: IShiftRole[][] = data.data.map(row =>
      flatMap(columns, col => row[col].split(","))
        .map(val => val.trim().toLowerCase())
        .filter(val => val !== "")
        .map(
          (val): IShiftRole => {
            const numNeededMatch = numNeededRegex.exec(val);
            if (numNeededMatch) {
              return {
                role: numNeededMatch[1].trim(),
                num_needed: parseInt(numNeededMatch[2], 10),
              };
            } else {
              return {
                role: val,
              };
            }
          }
        )
    );

    return {
      values: roles,
    };
  }

  validateParsedData(
    parsedData: IParsedData<Array<IShiftRole>>,
    columns: number[]
  ) {
    return {
      isValid: true,
    };
  }

  createSummary(
    parsedData: IParsedData<Array<IShiftRole>>,
    _columns: number[],
    followUpAnswers: FollowUpAnswerMap
  ): ISummary {
    const uniqueRoles: Record<string, boolean> = {};
    let firstRole: IShiftRole | undefined;
    parsedData.values.forEach(row => {
      row.forEach(role => {
        if (!uniqueRoles[role.role]) {
          uniqueRoles[role.role] = true;
        }
        if (!firstRole) {
          firstRole = role;
        }
      });
    });

    let firstRoleNumVolunteers = "";
    if (firstRole && firstRole.num_needed) {
      firstRoleNumVolunteers = `${firstRole.num_needed}`;
    } else if (followUpAnswers[REQUIRED_ROLES_NUM_TYPE_ID]) {
      firstRoleNumVolunteers =
        followUpAnswers[REQUIRED_ROLES_NUM_TYPE_ID] === ONE_REQUIRED
          ? "1"
          : "all";
    }

    return {
      status: "INFO",
      short: pluralize("role", Object.keys(uniqueRoles).length, true),
      full: {
        label: pluralize("role", Object.keys(uniqueRoles).length, true),
        summary: firstRole
          ? `Example: ${firstRole.role}; ${firstRoleNumVolunteers} required.`
          : "",
      },
    };
  }

  public getFollowUpQuestions(
    parsedData: IParsedData<Array<IShiftRole>>
  ): Array<IFollowUpQuestion> {
    if (
      parsedData.values.some(row =>
        row.some(role => role.num_needed === undefined)
      )
    ) {
      return [
        {
          id: REQUIRED_ROLES_NUM_TYPE_ID,
          questionText:
            "How many volunteers from each shift must have the required role if a specific number is not given?",
          options: [
            {
              label: "One",
              value: ONE_REQUIRED,
            },
            {
              label: "All",
              value: ALL_REQUIRED,
            },
          ],
        },
      ];
    }
    return [];
  }

  public mapRow(shift: IShift, parsedRow: Array<IShiftRole>) {
    return {
      ...shift,
      required_roles: parsedRow,
    };
  }
}
