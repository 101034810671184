import React, { useMemo } from "react";
import { Segment, Header, Button, Icon, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { SchedulerTable } from "../scheduler/SchedulerTable";
import { ColumnConfigurationScreen } from "./types";
import { ColumnMappingSummary } from "./ColumnMappingSummary";
import { useColumnConfigController } from "./columnConfigController";
import {
  ITableData,
  AsyncData,
  IColumnMapping,
} from "../scheduler/store/types";
import { FollowUpQuestionView } from "./FollowUpQuestionView";
import { CategoryColumnView } from "./CategoryColumnView";
import { CategoryIdType } from "../categories/constants";
import { computeTableCellInfo } from "./util";

const Wrapper = styled.div`
  display: flex;
  flex: 1 1;
  min-height: 0;
`;

// I wish I didn't need to use calc here... stupid Semantic UI  + Flexbox
const ContentPanel = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
  width: calc(100% - 300px);
`;

const TableContainer = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
`;

const ControlsContainer = styled.div`
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BottomSegment = styled(Segment)`
  &&& {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
`;

interface IColumnConfigurationProps {
  tableData: AsyncData<ITableData>;
  columnMappings: Array<IColumnMapping>;
  setColumnMappings(mappings: Array<IColumnMapping>): void;
  categoryIds: Array<CategoryIdType>;
  requiredCategoryIds: Array<CategoryIdType>;
  title: string;
}

export const ColumnConfiguration: React.FC<IColumnConfigurationProps> = (
  props: IColumnConfigurationProps
) => {
  const {
    selectedColumns,
    selectedCategory,
    screen,
    validationInfo,
    summary,
    followUpQuestions,
    followUpAnswers,
    currentFollowUpIndex,
    primaryButton,
    secondaryButton,
    ...controller
  } = useColumnConfigController(
    props.requiredCategoryIds,
    props.tableData,
    props.columnMappings,
    props.setColumnMappings
  );

  const { headers, data } = useMemo(
    () =>
      computeTableCellInfo(
        validationInfo,
        props.tableData,
        props.columnMappings,
        selectedColumns
      ),
    [validationInfo, props.tableData, props.columnMappings, selectedColumns]
  );

  if (props.tableData.loadState !== "LOADED") {
    return <div>Table data has not been loaded!</div>;
  }

  return (
    <Wrapper>
      <ContentPanel>
        <TableContainer>
          <Header as="h1">{props.title}</Header>
          <SchedulerTable
            headers={headers}
            data={data}
            height={400}
            cellErrors={validationInfo && validationInfo.cellErrors}
            onColumnClick={controller.toggleColumnSelection}
          />
        </TableContainer>
        <ControlsContainer>
          {screen === ColumnConfigurationScreen.ADDITIONAL_INFO ? (
            <FollowUpQuestionView
              selectedColumns={selectedColumns}
              selectedCategory={selectedCategory}
              summary={summary}
              validationInfo={validationInfo}
              followUpQuestions={followUpQuestions}
              followUpAnswers={followUpAnswers}
              currentFollowUpIndex={currentFollowUpIndex}
              tableData={props.tableData.value}
              handleFollowUpAnswered={controller.handleFollowUpAnswered}
            />
          ) : (
            <CategoryColumnView
              tableData={props.tableData.value}
              selectedColumns={selectedColumns}
              selectedCategory={selectedCategory}
              summary={summary}
              validationInfo={validationInfo}
              followUpQuestions={followUpQuestions}
              followUpAnswers={followUpAnswers}
              columnMappings={props.columnMappings}
              categoryIds={props.categoryIds}
              requiredCategoryIds={props.requiredCategoryIds}
              selectCategory={controller.selectCategory}
              toggleColumnSelection={controller.toggleColumnSelection}
            />
          )}
          <BottomSegment vertical>
            <Button onClick={secondaryButton.onClick}>
              {secondaryButton.text}
            </Button>
            <Popup
              disabled={!primaryButton.popupText}
              content={primaryButton.popupText}
              trigger={
                <div>
                  <Button
                    primary={primaryButton.primary}
                    positive={primaryButton.positive}
                    onClick={primaryButton.onClick}
                    disabled={primaryButton.disabled}
                  >
                    {primaryButton.text}
                    {primaryButton.iconName && (
                      <Icon name={primaryButton.iconName} />
                    )}
                  </Button>
                </div>
              }
            />
          </BottomSegment>
        </ControlsContainer>
      </ContentPanel>
      <ColumnMappingSummary
        columnMappings={props.columnMappings}
        categoryIds={props.categoryIds}
        deleteMapping={controller.deleteMapping}
        table={props.tableData.value}
        selectCategory={controller.selectCategory}
      />
    </Wrapper>
  );
};
