import {
  AsyncNotLoaded,
  AsyncLoading,
  AsyncLoaded,
  AsyncFailed,
} from "./types";

export function asyncNotLoaded(): AsyncNotLoaded {
  return {
    loadState: "NOT_STARTED",
  };
}

export function asyncLoading(): AsyncLoading {
  return {
    loadState: "LOADING",
  };
}

export function asyncLoaded<T>(value: T): AsyncLoaded<T> {
  return {
    loadState: "LOADED",
    value,
  };
}

export function asyncFailed(error: Error): AsyncFailed {
  return {
    loadState: "ERROR",
    error,
  };
}
