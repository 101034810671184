import React, { useState } from "react";
import styled from "styled-components";
import * as Papaparse from "papaparse";
import { saveAs } from "file-saver";
import {
  Container,
  Header,
  Button,
  Progress,
  Divider,
} from "semantic-ui-react";
import { SchedulerState } from "./store/schedulerStateContainer";
import { generateShifts, generateVolunteers } from "./schedulerUtils";
import { IScheduleResults } from "./types";
import { asyncLoaded, asyncLoading, asyncFailed } from "./store/util";
import { SchedulerTable } from "./SchedulerTable";
import { useInterval } from "../custom-hooks/useInterval";

const TableArea = styled.div`
  height: 400px;
  width: 100%;
  margin-top: 20px;
  background: "#deded";
  border: 1px solid gray;
  border-radius: 4px;

  p {
    text-align: center;
    padding-top: 180px;
    color: gray;

    &.error {
      color: red;
    }
  }
`;

const DownloadButton = styled(Button)`
  &&& {
    margin-top: 20px;
  }
`;

const StyledProgress = styled(Progress)`
  &&& {
    margin: 180px 50px;
  }
`;

interface IProps {}

export const ScheduleResults: React.FC<IProps> = (props: IProps) => {
  const {
    shiftData,
    volunteerData,
    shiftColumnMappings,
    volunteerColumnMappings,
    scheduleResults,
    setScheduleResults,
    derivedData,
    resultTable,
  } = SchedulerState.useContainer();
  const [loadPercentage, setLoadPercentage] = useState(0);

  useInterval(
    () => {
      if (scheduleResults.loadState === "LOADING") {
        setLoadPercentage(Math.min(loadPercentage + 1, 95));
      }
    },
    scheduleResults.loadState === "LOADING" ? 100 : null
  );

  function fetchScheduleResults() {
    setScheduleResults(asyncLoading());
    fetch("/api/v0/schedule", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        volunteers: generateVolunteers(
          volunteerColumnMappings,
          volunteerData,
          derivedData
        ),
        shifts: generateShifts(shiftColumnMappings, shiftData, derivedData),
      }),
    })
      .then(response => {
        if (!response.ok || response.status >= 400) {
          throw new Error(
            `Received response with status code ${response.status}.`
          );
        }
        return response.json() as Promise<IScheduleResults>;
      })
      .then((result: IScheduleResults) => {
        setScheduleResults(asyncLoaded(result));
        setLoadPercentage(0);
      })
      .catch(e => {
        // TODO: Error handling
        console.error(e);
        setScheduleResults(asyncFailed(e));
        setLoadPercentage(0);
      });
  }

  function downloadScheduleResults() {
    if (resultTable !== undefined) {
      const data = [resultTable.headers, ...resultTable.data];
      const csvText = Papaparse.unparse(data);
      const fileBlob = new Blob([csvText], { type: "text/csv;charset=utf-8" });
      saveAs(fileBlob, "schedule.csv");
    }
  }

  if (
    shiftData.loadState !== "LOADED" ||
    volunteerData.loadState !== "LOADED"
  ) {
    return <div></div>;
  }

  function maybeRenderDevDetails() {
    if (process.env.NODE_ENV === "development") {
      const volunteers = generateVolunteers(
        volunteerColumnMappings,
        volunteerData,
        derivedData
      );
      const shifts = generateShifts(
        shiftColumnMappings,
        shiftData,
        derivedData
      );
      return (
        <pre>
          {`Shifts: ${JSON.stringify(shifts, undefined, 4)}
                    
                        \nVolunteers: ${JSON.stringify(
                          volunteers,
                          undefined,
                          4
                        )}`}
        </pre>
      );
    }
    return null;
  }

  function renderTable() {
    if (scheduleResults.loadState === "LOADING") {
      return (
        <TableArea>
          <StyledProgress active percent={loadPercentage} color="blue">
            Generating schedule...
          </StyledProgress>
        </TableArea>
      );
    } else if (scheduleResults.loadState === "ERROR") {
      return (
        <TableArea>
          <p className="error">There was a problem generating the schedule.</p>
        </TableArea>
      );
    } else if (resultTable !== undefined) {
      return (
        <div>
          <SchedulerTable
            headers={resultTable.headers.map(text => ({ text }))}
            data={resultTable.data.map(row =>
              row.map(text => ({
                text,
              }))
            )}
          />
          <DownloadButton positive onClick={downloadScheduleResults}>
            Download schedule
          </DownloadButton>
          <Divider />
          Thanks for using VolSchedule! Please give us feedback about how we can
          improve at{" "}
          <a href="mailto:info@volschedule.com">info@volschedule.com</a>.
        </div>
      );
    } else {
      return (
        <TableArea>
          <p>Click "Generate schedule" to get your results!</p>
        </TableArea>
      );
    }
  }

  return (
    <Container>
      <Header as="h1">Generate Schedule</Header>
      <div>
        <Button
          primary
          onClick={fetchScheduleResults}
          disabled={scheduleResults.loadState === "LOADING"}
        >
          {resultTable !== undefined
            ? "Regenerate Schedule"
            : "Generate Schedule"}
        </Button>
      </div>
      {renderTable()}
      {maybeRenderDevDetails()}
    </Container>
  );
};
