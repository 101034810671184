import React, { PropsWithChildren } from "react";
import { Table, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { CellColorInfo } from "./types";

const HIGHLIGHT_TEXT_COLOR = "#ffffff";
const HIGHLIGHT_BACKGROUND_COLOR = "#5699cd";
const HEADER_HIGHLIGHT_BACKGROUND_COLOR = "#3e8bc6";

// Not sure how this passes TS typechecking
const StyledCell = styled(
  ({ background, clickable, highlight, textColor, ...rest }) => (
    <Table.Cell {...rest} />
  )
)`
  ${props => (props.clickable ? "cursor: pointer;" : "")}
  ${props => {
    if (props.background) {
      let ret = `
                background: ${props.background};
            `;
      if (props.textColor) {
        ret += `
                    color: ${props.textColor};
                `;
      }
      return ret;
    } else if (props.highlight) {
      return `
                background: ${HIGHLIGHT_BACKGROUND_COLOR};
                color: ${HIGHLIGHT_TEXT_COLOR}
            `;
    }

    return "";
  }}
`;

const StickyHeaderCell = styled(
  ({ background, clickable, highlight, textColor, ...rest }) => (
    <Table.HeaderCell {...rest} />
  )
)`
  position: sticky;
  top: 0;
  ${props =>
    props.clickable
      ? `&&&{
        cursor: pointer;
    }`
      : ""}
  ${props => {
    if (props.background) {
      let ret = `&&&{
                background: ${props.background};
            `;
      if (props.textColor) {
        ret += `
                    color: ${props.textColor};
                `;
      }
      ret += "}";
      return ret;
    } else if (props.highlight) {
      return `&&&{
                background: ${HEADER_HIGHLIGHT_BACKGROUND_COLOR};
                color: ${HIGHLIGHT_TEXT_COLOR}
            }`;
    }

    return "";
  }}
`;

interface ISchedulerTableCellProps {
  index: number;
  isHeader?: boolean;
  highlight?: boolean;
  color?: CellColorInfo;
  popupText?: string;
  onMouseOver?: (index: number) => void;
  onClick?: (columnIndex: number) => void;
}

export const SchedulerTableCell: React.FC<ISchedulerTableCellProps> = (
  props: PropsWithChildren<ISchedulerTableCellProps>
) => {
  function handleMouseOver() {
    if (props.onMouseOver) {
      props.onMouseOver(props.index);
    }
  }

  function handleClick() {
    if (props.onClick) {
      props.onClick(props.index);
    }
  }

  let content: React.ReactNode;
  if (props.popupText) {
    content = (
      <Popup
        content={props.popupText}
        trigger={<span>{props.children}</span>}
      />
    );
  } else {
    content = <span>{props.children}</span>;
  }

  const tableCellProps = {
    onMouseOver: props.onMouseOver ? handleMouseOver : undefined,
    highlight: props.highlight,
    clickable: !!props.onClick,
    background: props.color && props.color.background,
    textColor: props.color && props.color.textColor,
    onClick: handleClick,
  };
  const elementType = props.isHeader ? StickyHeaderCell : StyledCell;

  return React.createElement(elementType, tableCellProps, content);
};
