import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Menu, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { Routes } from "./constants";

const NavSegment = styled(Segment)`
  display: flex;
  align-items: center;
  &&& {
    border-radius: 0;
    margin: 0;
    padding-right: 10px;
  }
`;

const Logo = styled.h2`
  font-size: 32px;
  cursor: pointer;
  &&& {
    margin: 0 50px 0 20px;
  }
`;

const StyledMenu = styled(Menu)`
  &&& {
    margin-top: 0;
    flex-grow: 1;
  }
`;

export const Nav: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}>
) => {
  return (
    <NavSegment inverted>
      <Logo onClick={() => props.history.replace(Routes.HOME)}>
        VolSchedule
      </Logo>
      <StyledMenu secondary pointing inverted>
        <Menu.Item
          link
          name="Home"
          active={
            props.location.pathname === Routes.HOME ||
            props.location.pathname === ""
          }
          onClick={() => props.history.replace(Routes.HOME)}
        />
        <Menu.Item
          link
          name="Scheduler"
          active={props.location.pathname === Routes.SCHEDULER}
          onClick={() => props.history.replace(Routes.SCHEDULER)}
        />
        <Menu.Item
          link
          name="Guide"
          active={props.location.pathname === Routes.GUIDES}
          onClick={() => props.history.replace(Routes.GUIDES)}
        />
        <Menu.Item
          link
          name="FAQ"
          active={props.location.pathname === Routes.FAQ}
          onClick={() => props.history.replace(Routes.FAQ)}
        />
        <Menu.Item
          link
          name="Contact"
          position="right"
          active={props.location.pathname === Routes.CONTACT}
          onClick={() => props.history.replace(Routes.CONTACT)}
        />
      </StyledMenu>
    </NavSegment>
  );
};
