import React from "react";
import { Icon, Step } from "semantic-ui-react";
import styled from "styled-components";
import { SchedulerState } from "./store/schedulerStateContainer";
import { SchedulerPage } from "./store/types";

const FullWidthSteps = styled(Step.Group)`
  && {
    width: 100%;
    margin-bottom: 0;
    flex-shrink: 0;
  }
`;

export const SchedulerSteps: React.FC = () => {
  const {
    page,
    maxPage,
    setPage,
    shiftData,
    volunteerData,
  } = SchedulerState.useContainer();
  const dataMissing =
    shiftData.loadState !== "LOADED" || volunteerData.loadState !== "LOADED";

  return (
    <FullWidthSteps>
      <Step
        active={page === SchedulerPage.UPLOAD}
        onClick={
          page > SchedulerPage.UPLOAD
            ? () => setPage(SchedulerPage.UPLOAD)
            : undefined
        }
      >
        <Icon name="upload" />
        <Step.Content>
          <Step.Title>1. Upload</Step.Title>
          <Step.Description>Upload spreadsheets</Step.Description>
        </Step.Content>
      </Step>
      <Step
        active={page === SchedulerPage.SHIFTS}
        disabled={maxPage < SchedulerPage.SHIFTS || dataMissing}
        onClick={() => setPage(SchedulerPage.SHIFTS)}
      >
        <Icon name="calendar alternate" />
        <Step.Content>
          <Step.Title>2. Shifts</Step.Title>
          <Step.Description>Configure shifts</Step.Description>
        </Step.Content>
      </Step>
      <Step
        active={page === SchedulerPage.VOLUNTEERS}
        disabled={maxPage < SchedulerPage.VOLUNTEERS || dataMissing}
        onClick={() => setPage(SchedulerPage.VOLUNTEERS)}
      >
        <Icon name="group" />
        <Step.Content>
          <Step.Title>3. Volunteers</Step.Title>
          <Step.Description>Configure volunteers</Step.Description>
        </Step.Content>
      </Step>
      <Step
        active={page === SchedulerPage.SCHEDULE}
        disabled={maxPage < SchedulerPage.SCHEDULE || dataMissing}
        onClick={() => setPage(SchedulerPage.SCHEDULE)}
      >
        <Icon name="table" />
        <Step.Content>
          <Step.Title>4. Schedule</Step.Title>
          <Step.Description>Generate schedule</Step.Description>
        </Step.Content>
      </Step>
    </FullWidthSteps>
  );
};
