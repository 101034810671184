import React from "react";
import { Helmet } from "react-helmet-async";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  min-height: 0;
  flex: 1 1;
  background-color: white;
`;

export const FAQ: React.FC = () => {
  return (
    <Wrapper>
      <Container text>
        <h1>FAQ</h1>
        <h3>
          Does VolSchedule make software for hours tracking or volunteer
          management?
        </h3>
        <p>
          Nope. VolSchedule does one thing -- generating volunteer schedules
          with time constraints -- and does it well.
        </p>
        <h3>
          Are the schedules that VolSchedule creates guaranteed to be optimal?
        </h3>
        <p>
          Generated schedules are guaranteed to respect any hard constraints
          you've set: volunteers won't be assigned to times when they're
          unavailable, all required roles will be fulfilled, and the number of
          volunteers in a shift won't lie outside the minimum and maximum number
          of volunteers (unless we couldn't schedule that shift at all). Beyond
          that, it's hard to define "optimal", but we do our best to respect
          volunteer priority and site/group requests, while also assigning as
          many volunteers and filling as many shifts as possible.
        </p>
        <h3>Is VolSchedule secure?</h3>
        <p>
          We take a simple and effective approach to data security: we don't
          store your volunteer data. Once we've generated your schedule, we
          don't hold on to anything on our servers. All network requests are
          SSL-encrypted to prevent man-in-the-middle attacks.
        </p>
        <h3>My use case has a special rule that VolSchedule doesn't support</h3>
        <p>
          First, see if you can find a creative way to stretch the existing
          categories. For example, returning volunteers could be given a higher
          priority; or if a site is flexible on timing, try including multiple
          time ranges in separate columns on the Shift spreadsheet. If that
          doesn't work, please feel free to reach out to us at{" "}
          <a href="mailto:info@volschedule.com">info@volschedule.com</a>.
        </p>
        <h3>Which browsers are supported?</h3>
        <p>
          Modern versions of Chrome and Firefox are recommended for running the
          scheduler.
        </p>
      </Container>
      <Helmet>
        <title>VolSchedule | FAQ</title>
        <meta
          name="description"
          content="Frequently asked questions about VolSchedule."
        />
      </Helmet>
    </Wrapper>
  );
};
