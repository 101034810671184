export type ColumnConfigurationScreen =
  | "SELECT_CATEGORY_OR_COLUMN"
  | "SELECT_CATEGORY"
  | "SELECT_COLUMN"
  | "CONFIRM_SELECTION"
  | "ADDITIONAL_INFO";

export const ColumnConfigurationScreen = {
  SELECT_CATEGORY_OR_COLUMN: "SELECT_CATEGORY_OR_COLUMN" as ColumnConfigurationScreen,
  SELECT_CATEGORY: "SELECT_CATEGORY" as ColumnConfigurationScreen,
  SELECT_COLUMN: "SELECT_COLUMN" as ColumnConfigurationScreen,
  CONFIRM_SELECTION: "CONFIRM_SELECTION" as ColumnConfigurationScreen,
  ADDITIONAL_INFO: "ADDITIONAL_INFO" as ColumnConfigurationScreen,
};

export interface IButtonConfig {
  text: string;
  onClick(): void;
  primary?: boolean;
  positive?: boolean;
  disabled?: boolean;
  iconName?: "arrow right";
  popupText?: string;
}
