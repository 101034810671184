import { Category } from "../category";
import { IShift } from "../../scheduler/types";
import { CategoryIdType, SHIFT_TYPE } from "../constants";
import { FollowUpAnswerMap } from "../types";

export abstract class ShiftCategory<T> extends Category<T> {
  constructor(params: {
    id: CategoryIdType;
    name: string;
    detail: string;
    color: string;
  }) {
    super({
      ...params,
      type: SHIFT_TYPE,
    });
  }

  public abstract mapRow(
    shift: IShift,
    parsedRow: T,
    followUpAnswers: FollowUpAnswerMap
  ): Array<IShift> | IShift;
}
