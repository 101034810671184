export const EXAMPLE_SHIFT_DATA = [
  ["School", "Class", "Time 1", "Time 2", "Max volunteers", "Roles"],
  ["Everytown High", "Murray", "Mon 9-10", "", "5", "Driver"],
  ["Everytown High", "Smith", "Tues 8-10", "Thurs 8-10", "4", "Driver"],
  ["Rival High", "Duncan", "Fri 3-5", "", "4", ""],
  ["Rival High", "Poole", "Wed 3-5", "Fri 3-5", "4", "Driver"],
  ["Everytown Middle", "Albertson", "Thurs 1-2", "", "5", "Driver"],
  ["Everytown Middle", "Baker", "Mon 11-12", "Wed 11-12", "5", ""],
  ["Everytown Elementary", "Carlisle", "Thurs 4-5", "", "4", "Driver"],
  ["Everytown Elementary", "Ellis", "Thurs 4-5", "", "5", "Driver"],
  ["Rival Middle", "Fairfield", "Tuesday 3-4", "", "5", "Driver"],
  ["Rival Middle", "Garland", "Sun 3-4", "", "4", "Driver"],
];

export const EXAMPLE_VOLUNTEER_DATA = [
  ["Name", "Email", "Avail 1", "Avail 2", "Priority", "Roles"],
  ["George Washington", "george@example.com", "Wed 8-1", "", "1", "Driver"],
  ["John Adams", "jadams@example.com", "Thurs 8-2", "Mon 8-2", "1", ""],
  ["Thomas Jefferson", "tj@example.com", "Tues 12-5", "", "1", ""],
  ["James Madison", "madison@example.com", "Fri 8-5", "", "1", ""],
  ["James Monroe", "monroe@example.com", "Thurs 11-2", "", "1", "Driver"],
  ["John Adams", "jqadams@example.com", "Fri 8-10", "", "1", ""],
  ["Andrew Jackson", "andrew@example.com", "Thu 9-5", "", "1", "Driver"],
  ["Martin van Buren", "mvburen@example.com", "Sun 1-5", "", "2", ""],
  ["William Henry Harrison", "whharrison@example.com", "Mon 8-2", "", "2", ""],
  ["John Tyler", "jtyler@example.com", "Thurs 3-8", "", "1", "Driver"],
  ["James K. Polk", "jpolk@example.com", "Wed 10-1", "", "1", ""],
  ["Zachary Taylor", "ztaylor@example.com", "Tues 8-10", "", "1", ""],
  [
    "Millard Fillmore",
    "mfillmore@example.com",
    "Tues 8-11",
    "Thurs 8-11",
    "2",
    "Driver",
  ],
  ["Franklin Pierce", "fpierce@example.com", "Mon 12-2", "", "2", ""],
  ["James Buchanan", "jbuchanan@example.com", "Fri 12-4", "", "2", ""],
  [
    "Abraham Lincoln",
    "alincoln@example.com",
    "Mon 8-4",
    "Tues 8-4",
    "1",
    "Driver",
  ],
  ["Andrew Johnson", "ajohnson@example.com", "Wed 8-1", "", "3", ""],
  ["Ulysses S. Grant", "ugrant@example.com", "Thurs 8-2", "", "1", ""],
  ["Rutherford B. Hayes", "rhayes@example.com", "Tues 12-5", "", "1", ""],
  ["James Garfield", "jgarfield@example.com", "Fri 8-5", "", "1", "Driver"],
  ["Chester A. Arthur", "sideburns@example.com", "Thurs 11-2", "", "2", ""],
  [
    "Grover Cleveland",
    "gcleveland@example.com",
    "Fri 8-10",
    "Sun 1-5",
    "2",
    "Driver",
  ],
  ["Benjamin Harrison", "bharrison@example.com", "Thu 9-5", "", "1", ""],
  ["Grover Cleveland", "gcleveland2@example.com", "Sun 1-5", "", "1", ""],
  ["William McKinley", "wmckinley@example.com", "Mon 8-2", "", "1", "Driver"],
  ["Theodore Roosevelt", "troosevelt@example.com", "Thurs 3-8", "", "1", ""],
  ["William Howard Taft", "whtaft@example.com", "Wed 10-1", "", "1", ""],
  [
    "Woodrow Wilson",
    "wwilson@example.com",
    "Tues 8-10",
    "Fri 12-4",
    "1",
    "Driver",
  ],
  ["Warren Harding", "wharding@example.com", "Tues 8-11", "", "2", ""],
  ["Calvin Coolidge", "ccoolidge@example.com", "Mon 12-2", "", "2", "Driver"],
  ["Herbert Hoover", "hhoover@example.com", "Fri 12-4", "", "2", ""],
  ["Franklin D. Roosevelt", "fdr@example.com", "Mon 8-4", "", "1", "Driver"],
  ["Harry S Truman", "hstruman@example.com", "Wed 8-1", "", "1", ""],
  ["Dwight D. Eisenhower", "ike@example.com", "Thurs 8-2", "Fri 8-10", "1", ""],
  ["John F. Kennedy", "jfk@example.com", "Tues 12-5", "", "1", "Driver"],
  ["Lyndon B. Johnson", "lbj@example.com", "Fri 8-5", "", "1", ""],
  ["Richard Nixon", "rnixon@example.com", "Thurs 11-2", "", "2", "Driver"],
  ["Gerald Ford", "gford@example.com", "Fri 8-10", "", "1", ""],
  ["Jimmy Carter", "jcarter@example.com", "Thu 9-5", "Mon 8-2", "1", ""],
  ["Ronald Reagan", "rreagan@example.com", "Sun 1-5", "", "1", ""],
  ["George Bush", "ghwbush@example.com", "Mon 8-2", "", "1", "Driver"],
  ["Bill Clinton", "bclinton@example.com", "Thurs 3-8", "", "1", "Driver"],
  ["George Bush", "gwbush@example.com", "Wed 10-1", "", "1", ""],
  ["Barack Obama", "bobama@example.com", "Tues 8-10", "Mon 8-2", "1", ""],
  [
    "Donald Trump",
    "dtrump@example.com",
    "Monday 9:00am-9:01am",
    "",
    "3",
    "Driver",
  ],
];
