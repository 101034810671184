/*
 * Core Scheduler state that must be preserved across tab changes
 */

import { useState, useMemo } from "react";
import { createContainer } from "unstated-next";
import {
  SchedulerPage,
  ISchedulerState,
  IColumnMapping,
  AsyncData,
  ITableData,
} from "./types";
import { asyncNotLoaded, asyncLoaded } from "./util";
import { IScheduleResults, DerivedDataMap } from "../types";
import { getCategory } from "../../categories/categoryMap";
import {
  VOLUNTEER_UNIQUE_ID,
  SITE_ID,
  SHIFT_DATETIME_ID,
  DATE_TIME_JOIN_TYPE_ID,
  JOIN_AND,
} from "../../categories/constants";
import { IDateTimeRange } from "../../categories/shared/types";
import { getFormattedDayAndTime } from "../../categories/shared/dateTimeUtils";
import {
  EXAMPLE_SHIFT_DATA,
  EXAMPLE_VOLUNTEER_DATA,
} from "../../guides/constants";

const initialSchedulerState: ISchedulerState = {
  page: SchedulerPage.UPLOAD,
  maxPage: SchedulerPage.UPLOAD,
  shiftData:
    process.env.NODE_ENV === "development"
      ? asyncLoaded({
          headers: EXAMPLE_SHIFT_DATA[0],
          data: EXAMPLE_SHIFT_DATA.slice(1),
        })
      : asyncNotLoaded(),
  shiftColumnMappings: [],
  volunteerColumnMappings: [],
  volunteerData:
    process.env.NODE_ENV === "development"
      ? asyncLoaded({
          headers: EXAMPLE_VOLUNTEER_DATA[0],
          data: EXAMPLE_VOLUNTEER_DATA.slice(1),
        })
      : asyncNotLoaded(),
};

function useSchedulerState(initialState = initialSchedulerState) {
  const [page, doSetPage] = useState(initialState.page);
  const [maxPage, setMaxPage] = useState(initialState.maxPage);
  const [shiftData, setShiftData] = useState(initialState.shiftData);
  const [shiftColumnMappings, setShiftColumnMappings] = useState<
    Array<IColumnMapping>
  >(initialState.shiftColumnMappings);
  const [volunteerColumnMappings, setVolunteerColumnMappings] = useState<
    Array<IColumnMapping>
  >(initialState.volunteerColumnMappings);
  const [volunteerData, setVolunteerData] = useState(
    initialState.volunteerData
  );
  const [scheduleResults, setScheduleResults] = useState<
    AsyncData<IScheduleResults>
  >(asyncNotLoaded());

  const derivedShiftData = useMemo(
    () => getMappedData(shiftData, shiftColumnMappings),
    [shiftData, shiftColumnMappings]
  );

  const derivedVolunteerData = useMemo(
    () => getMappedData(volunteerData, volunteerColumnMappings),
    [volunteerData, volunteerColumnMappings]
  );

  const derivedData = useMemo(
    () => ({
      ...derivedShiftData,
      ...derivedVolunteerData,
    }),
    [derivedShiftData, derivedVolunteerData]
  );

  const resultTable: ITableData | undefined = useMemo(() => {
    if (
      shiftData.loadState !== "LOADED" ||
      volunteerData.loadState !== "LOADED" ||
      scheduleResults.loadState !== "LOADED" ||
      derivedData[SHIFT_DATETIME_ID] === undefined
    ) {
      return undefined;
    }

    return getResultTable(
      shiftData.value,
      volunteerData.value,
      shiftColumnMappings,
      volunteerColumnMappings,
      derivedData[SHIFT_DATETIME_ID].parsedData.values,
      scheduleResults.value
    );
  }, [
    scheduleResults,
    derivedData,
    shiftData,
    volunteerData,
    shiftColumnMappings,
    volunteerColumnMappings,
  ]);

  function setPage(p: number) {
    if (p > maxPage) {
      setMaxPage(p);
    }
    doSetPage(p);
  }

  return {
    page,
    setPage,
    maxPage,
    shiftData,
    setShiftData,
    shiftColumnMappings,
    setShiftColumnMappings,
    volunteerColumnMappings,
    setVolunteerColumnMappings,
    volunteerData,
    setVolunteerData,
    scheduleResults,
    setScheduleResults,
    derivedData,
    resultTable,
  };
}

export const SchedulerState = createContainer(useSchedulerState);

function getMappedData(
  data: AsyncData<ITableData>,
  columnMappings: IColumnMapping[]
): DerivedDataMap {
  if (data.loadState !== "LOADED") {
    return {};
  }

  const columnData: DerivedDataMap = {};
  columnMappings.forEach(mapping => {
    const category = getCategory(mapping.category);
    const parsedData = category.parseData(
      data.value,
      mapping.columns,
      mapping.followUpAnswers
    );
    const followUpQuestions = category.getFollowUpQuestions(
      parsedData,
      mapping.columns
    );
    const validation = category.validate(
      data.value,
      parsedData,
      mapping.columns,
      followUpQuestions,
      mapping.followUpAnswers
    );
    columnData[mapping.category] = {
      parsedData,
      validation,
      followUpQuestions,
      summary: category.getSummary(
        parsedData,
        mapping.columns,
        validation,
        mapping.followUpAnswers
      ),
    };
  });

  return columnData;
}

function getResultTable(
  rawShiftData: ITableData,
  rawVolunteerData: ITableData,
  shiftColumnMappings: Array<IColumnMapping>,
  volunteerColumnMappings: Array<IColumnMapping>,
  parsedShiftTimes: Array<Array<IDateTimeRange>>,
  results: IScheduleResults
): ITableData {
  const headers: string[] = [];
  const rows: string[][] = [];
  const volunteerIdColumns: number[] = [];
  const siteColumns: number[] = [];

  volunteerColumnMappings
    .filter(m => m.category === VOLUNTEER_UNIQUE_ID)
    .forEach(m => {
      m.columns.forEach(col => {
        headers.push(rawVolunteerData.headers[col]);
        volunteerIdColumns.push(col);
      });
    });
  shiftColumnMappings
    .filter(m => m.category === SITE_ID)
    .forEach(m => {
      m.columns.forEach(col => {
        headers.push(rawShiftData.headers[col]);
        siteColumns.push(col);
      });
    });
  const dateTimeCategory = shiftColumnMappings.find(
    m => m.category === SHIFT_DATETIME_ID
  );
  const hasMultipleTimes =
    dateTimeCategory &&
    dateTimeCategory.followUpAnswers[DATE_TIME_JOIN_TYPE_ID] === JOIN_AND;
  let numTimeColumns = 1;

  if (!hasMultipleTimes) {
    headers.push("Day");
    headers.push("Time");
  }

  results.assignments.forEach(assignment => {
    const resultRow: string[] = [];
    const volRow = rawVolunteerData.data[assignment.volunteer_id];
    const shiftRow = rawShiftData.data[assignment.shift_id];
    volunteerIdColumns.forEach(col => {
      resultRow.push(volRow[col]);
    });
    siteColumns.forEach(col => {
      resultRow.push(shiftRow[col]);
    });
    const resultDateTimes = parsedShiftTimes[assignment.shift_id];
    if (hasMultipleTimes) {
      resultDateTimes.forEach(dateTime => {
        const { day, time } = getFormattedDayAndTime(dateTime);
        resultRow.push(`${day}, ${time}`);
      });
      if (resultDateTimes.length > numTimeColumns) {
        numTimeColumns = resultDateTimes.length;
      }
    } else if (resultDateTimes[assignment.shift_option_number]) {
      const dateTime = resultDateTimes[assignment.shift_option_number];
      const { day, time } = getFormattedDayAndTime(dateTime);
      resultRow.push(day);
      resultRow.push(time);
    } else {
      // TODO: Should we throw an error here?
      resultRow.push("No value found.");
    }
    rows.push(resultRow);
  });

  if (hasMultipleTimes) {
    for (let i = 0; i < numTimeColumns; i++) {
      headers.push(`Time ${i + 1}`);
    }
  }

  return {
    headers,
    data: rows,
  };
}
